import { useState, useEffect } from 'react'
import { useFetchFloorCellsLazyQuery } from '../ZoneCreateEditPage/GraphQl/gql.generated'
import { idxToColor } from '../../../common/utils/floorLayouts'
import { isEmpty } from 'lodash'
import { stubTrue } from 'lodash'

const useFloorLayout = ({ sensor, sensorLocationSet, setSensorLocationSet }) => {
  const [grid, setGrid] = useState([[]])

  const setUpGrid = (data) => {
    const num_rows = sensor.building.numFloorCellRows
    const num_columns = sensor.building.numFloorCellColumns

    const floorCells = data.fetchFloorCells
    const newGrid = new Array(num_rows).fill(null).map(() => new Array(num_columns).fill(null))
    for (let row = 0; row < num_rows; row++) {
      for (let col = 0; col < num_columns; col++) {
        // We now want to fill out the props of the empty cells
        // By default, all cells that don't belong to the zone are disabled.
        // For information on what all of these mean, please check out the documentation for FloorCell
        newGrid[row][col] = {
          unitId: null,
          props: {
            disabled: true
          },
          styleProps: {
            width: 30,
            height: 30
          }
        }
      }
    }
    for (let cell of floorCells) {
      newGrid[cell.row][cell.column].unitId = cell.unit.id
      newGrid[cell.row][cell.column].props = {
        clickable: false, // read-only
        toggled: cell.unit.id == sensor.unit.id,
        toggledBgColor: idxToColor(0),
        disabled: cell.unit.id != sensor.unit.id
      }
    }
    setGrid(newGrid)
  }

  const [fetchFloorCells, { data: floorCellsData, loading: floorCellsLoading }] = useFetchFloorCellsLazyQuery(
    {
      onCompleted: (data) => {
        setUpGrid(data)
      },
      fetchPolicy: 'network-only',
      defaultOptions: {
        notifyOnNetworkStatusChange: true
      }
    }
  )

  useEffect(() => {
    if (!isEmpty(sensor)) {
      if (isEmpty(sensor.floorCell)) {
        setSensorLocationSet(false)
      } else {
        setSensorLocationSet(true)
        fetchFloorCells({
          variables: {
            buildingId: sensor.building.id,
            floor: sensor.unit.floor
          }
        })
      }
    }
  }, [sensor])

  useEffect(() => {
    // If sensor already has a set location, highlight it when user selects corresponding unit/zone
    if (!isEmpty(sensor)) {
      const sensorRow = sensor.floorCell.row
      const sensorCol = sensor.floorCell.column
      if (sensorLocationSet && sensorRow != null && sensorCol != null) {
        const floorCellElement = document.getElementById(`floorcell-${sensorRow}-${sensorCol}`)
        if (floorCellElement) floorCellElement.style.backgroundColor = 'red'
      }
    }
  }, [sensorLocationSet, grid])

  return {
    floorCellsLoading,
    grid
  }
}

export default useFloorLayout
