import {
  FormControl,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
  Chip,
  TextField
} from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Controller } from 'react-hook-form'
import FormCheckbox from '../atoms/CheckmarkCell'
import TooltipIcon from '../atoms/TooltopIcon'
import React, { useEffect } from 'react'
import timezones from 'timezones-list'
import { isMobile } from '../../common/utils/display'
import { capitalize, isEmpty } from 'lodash'
import { parseTimeToNewDate } from '../../common/utils/dates'

// This component allows setting of time alert config for door and motion sensors, depending on configType value being passed
// It can also be adjusted in future to support user alerting time period configuration
const TimeConfig = ({
  control,
  configEnabled,
  configType,
  hoursValues,
  setHoursValues,
  setValue,
  title,
  fieldId,
  tooltip = null
}) => {
  const daysOfWeek = [
    { label: 'Monday', value: 0 },
    { label: 'Tuesday', value: 1 },
    { label: 'Wednesday', value: 2 },
    { label: 'Thursday', value: 3 },
    { label: 'Friday', value: 4 },
    { label: 'Saturday', value: 5 },
    { label: 'Sunday', value: 6 }
  ]

  useEffect(() => {
    hoursValues[configType + 'AlertHoursEnabled'] ||= false
    hoursValues[configType + 'AlertDaysArr'] ||= []
    hoursValues[configType + 'AlertHoursStart'] = isEmpty(hoursValues[configType + 'AlertHoursStart'])
      ? new Date()
      : parseTimeToNewDate(hoursValues[configType + 'AlertHoursStart'])
    hoursValues[configType + 'AlertHoursEnd'] = isEmpty(hoursValues[configType + 'AlertHoursEnd'])
      ? new Date()
      : parseTimeToNewDate(hoursValues[configType + 'AlertHoursEnd'])
    hoursValues[configType + 'AlertTimezone'] ||= 'America/Edmonton'
    hoursValues[configType + 'AlertWeekendHolidays'] ||= false
  }, [])

  const displayIsMobile = isMobile()

  return (
    <>
      <Grid container spacing={0} sx={{ pt: 0 }}>
        <Grid item xs={3} sm={5} md={5} lg={5} sx={{ mr: displayIsMobile ? 0 : -3.2 }}>
          <FormControl fullWidth>
            <Controller
              name={fieldId}
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  label={
                    <Grid container direction='row' alignItems='center'>
                      <Grid item>
                        <Typography>{title}</Typography>
                      </Grid>
                    </Grid>
                  }
                  checked={field.value}
                  onChange={(e) => setValue(fieldId, e.target.checked)}
                />
              )}
            />
          </FormControl>
        </Grid>
        {configEnabled && (
          <Grid item xs={9} sm={7} md={7} lg={7} sx={{ mt: 0.3 }}>
            <Grid container>
              <Grid item sx={{ mr: -2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hoursValues[configType + 'AlertHoursEnabled']}
                      onChange={(e) =>
                        setHoursValues({
                          ...hoursValues,
                          [configType + 'AlertHoursEnabled']: e.target.checked
                        })
                      }
                    />
                  }
                  label={`Set ${capitalize(configType)} Alert Hours`}
                />
              </Grid>
              {tooltip && (
                <Grid item>
                  <TooltipIcon title={tooltip} textFontSize={14} />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {hoursValues[configType + 'AlertHoursEnabled'] && (
        <Box
          sx={{
            border: '1px solid lightgrey',
            padding: 0.5,
            pt: 1.5,
            pr: 4,
            borderRadius: 1,
            mb: 1
          }}
        >
          <Grid container spacing={0} sx={{ pt: 1, pl: 2, pb: 2 }}>
            <FormControl>
              <InputLabel id='days-of-week-label'>Select Days</InputLabel>
              <Select
                sx={{ minWidth: 200 }}
                labelId='days-of-week-label'
                multiple
                placeholder='Select Days'
                value={hoursValues[configType + 'AlertDaysArr']}
                onChange={(e) =>
                  setHoursValues({ ...hoursValues, [configType + 'AlertDaysArr']: e.target.value })
                }
                input={<OutlinedInput label='Select Days' />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected
                      .slice()
                      .sort()
                      .map((value) => (
                        <Chip
                          key={value}
                          label={daysOfWeek.find((day) => day.value === value).label}
                          onDelete={() => {}}
                        />
                      ))}
                  </Box>
                )}
              >
                {daysOfWeek.map((day) => (
                  <MenuItem key={day.value} value={day.value}>
                    <Checkbox checked={hoursValues[configType + 'AlertDaysArr'].indexOf(day.value) > -1} />
                    <ListItemText primary={day.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container spacing={2} sx={{ pt: 1, pl: 2, pb: 2 }}>
            <Grid item sx={{ maxWidth: 180 }}>
              <TimePicker
                label='Start Time'
                ampm={false}
                value={hoursValues[configType + 'AlertHoursStart']}
                onChange={(e) => setHoursValues({ ...hoursValues, [configType + 'AlertHoursStart']: e })}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={7} sm={2} md={2} lg={2}>
              <Typography sx={{ mt: displayIsMobile ? 0 : 2, ml: displayIsMobile ? -6 : 0 }}>
                until
              </Typography>
            </Grid>
            <Grid item sx={{ maxWidth: 180 }}>
              <TimePicker
                label='End Time'
                ampm={false}
                value={hoursValues[configType + 'AlertHoursEnd']}
                onChange={(e) => {
                  setHoursValues({ ...hoursValues, [configType + 'AlertHoursEnd']: e })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ pt: 2, pl: 4.5, pb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={hoursValues[configType + 'AlertWeekendHolidays']}
                  onChange={(e) =>
                    setHoursValues({
                      ...hoursValues,
                      [configType + 'AlertWeekendHolidays']: e.target.checked
                    })
                  }
                />
              }
              label='Alert on Weekends & Holidays (24hrs)'
            />
          </Grid>
          <Grid container spacing={2} sx={{ pt: 2, pl: 4, pb: 2 }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id='timezone-select-label'>Select Timezone</InputLabel>
              <Select
                labelId='timezone-select-label'
                value={hoursValues[configType + 'AlertTimezone']}
                onChange={(e) =>
                  setHoursValues({ ...hoursValues, [configType + 'AlertTimezone']: e.target.value })
                }
                input={<OutlinedInput label='Select Timezone' />}
                sx={{
                  textAlign: 'left',
                  '& .MuiSelect-select': {
                    alignItems: 'left'
                  }
                }}
              >
                {timezones.map((timezone) => (
                  <MenuItem key={timezone.name} value={timezone.tzCode}>
                    {timezone.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default TimeConfig
