import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography
} from '@mui/material'

import { NUMBER_OF_ROWS_PER_PAGE_TEN } from '../../../common/constants/pagination'

import Card from '../../molecules/Card'
import AlertsTable from '../../organisms/AlertsTable'
import SensorShowInformation from '../../organisms/SensorShowInformation'
import SensorEventsTable from '../../organisms/SensorEventsTable/SensorEventsTable'

import useSensorPage from './useSensorPage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useLocation } from 'react-router-dom'

import useFloorLayout from './useFloorLayout'
import FloorGrid from '../../organisms/FloorGrid'
import CardHeader from '../../atoms/CardHeader/CardHeader'

import { useGetCurrentUserTypeQuery } from '../PropertyManagersPage/GraphQl/gql.generated'
import { TYPE_SUPER_ADMIN } from '../../../common/constants/users'
import { get } from 'lodash'

const SensorPage = () => {
  const { loading, userType, sensor, building, unit, sensorLocationPhoto } = useSensorPage()
  const useQuery = new URLSearchParams(useLocation().search)
  const collapseDetails = useQuery.get('collapse_details') === 'true'

  const [isDetailsExpanded, setIsDetailsExpanded] = useState(!collapseDetails)
  const handleDetailsAccordionChange = (event, newExpandedState) => setIsDetailsExpanded(newExpandedState)

  const [isAlertsExpanded, setIsAlertsExpanded] = useState(false) // collapsed by default
  const handleAlertsAccordionChange = (event, newExpandedState) => setIsAlertsExpanded(newExpandedState)

  const [sensorLocationSet, setSensorLocationSet] = useState(false)
  const { floorCellsLoading, grid } = useFloorLayout({
    sensor,
    sensorLocationSet,
    setSensorLocationSet
  })

  // Get current user type
  const { data: userData } = useGetCurrentUserTypeQuery()
  const currentUserType = get(userData, ['getCurrentUser', 'type'], '')

  const tableSensorEventsContainerFixedHeight = '600px' // height of tables "parent" card

  // height of the actual table relative to the "parent" card's height (tableContainerFixedHeight)
  const innerTableRelativeHeightAlerts = '90%' // only 80% to account for the event type filter
  const innerTableRelativeHeightSensorEvents = '90%'

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4} xl={3}>
          <Accordion expanded={isDetailsExpanded} onChange={handleDetailsAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon>Hide</ExpandMoreIcon>}>
              <Typography variant='h6' sx={{ width: '100%', textAlign: 'center' }}>
                Sensor Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Left side of page */}
              <SensorShowInformation
                sensor={sensor}
                sensorLocationPhoto={sensorLocationPhoto}
                building={building}
                unit={unit}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* Right side of page */}
        <Grid item xs={12} lg={8} xl={9}>
          <Card
            title={'Sensor Events'}
            maxWidth={false}
            containerSx={{ maxHeight: tableSensorEventsContainerFixedHeight, overflow: 'scroll' }}
            gridSx={{ maxHeight: innerTableRelativeHeightSensorEvents }}
          >
            <SensorEventsTable
              enableAutoRefresh={true}
              sensor={sensor}
              building={building}
              userType={userType}
              numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_TEN}
              tableContainerSx={{
                maxHeight: '100%' /* ensures table takes up 100% of innerTableRelativeHeight */
              }}
              tableSx={{
                minHeight: '100px',
                height: '65%' /* actual table height. Need to ensure room for pagination buttons */
              }}
            />
          </Card>
          <br />

          <Accordion expanded={isAlertsExpanded} onChange={handleAlertsAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon>Hide</ExpandMoreIcon>}>
              <Box sx={{ width: '100%' }}>
                <CardHeader title='Alerts' />
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ height: innerTableRelativeHeightAlerts }}>
              <AlertsTable
                building={building}
                sensor={sensor}
                showEventTypeFilter={true}
                numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_TEN}
                tableContainerSx={{ maxHeight: '95%' }}
              />
            </AccordionDetails>
          </Accordion>
          <br />

          {currentUserType === TYPE_SUPER_ADMIN && (
            <>
              <Card
                title={'Sensor Location'}
                maxWidth={false}
                gridSx={{
                  height: innerTableRelativeHeightAlerts,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {floorCellsLoading && <CircularProgress />}
                {!floorCellsLoading && (
                  <>
                    {sensorLocationSet && (
                      <Stack direction='column' sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant='h9'>North</Typography>
                        <FloorGrid grid={grid} rotation={building?.rotationToNorth} />
                      </Stack>
                    )}
                    {!sensorLocationSet && (
                      <Typography variant='h7'>
                        This sensor does not have its location set. Click on "Edit Sensor" to set it.
                      </Typography>
                    )}
                  </>
                )}
              </Card>
              <br />
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default SensorPage
