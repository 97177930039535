import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorFragment = { __typename?: 'Sensor', id: string, hardwareId: string, buildingId?: string | null, unitId?: string | null, deviceName?: string | null, location?: string | null, inUse: boolean, enableAlerts: boolean, monitorWater: boolean, monitorTemperature: boolean, monitorHumidity: boolean, monitorDoor: boolean, monitorSound: boolean, monitorPower: boolean, waterSensitivity?: number | null, lowTemperatureThreshold?: number | null, highTemperatureThreshold?: number | null, lowHumidityThreshold?: number | null, highHumidityThreshold?: number | null, highDecibelThreshold?: number | null, probeType?: string | null, notes?: string | null, countPeople: boolean, countPeopleInLabel?: string | null, countPeopleOutLabel?: string | null, floorCell?: { __typename?: 'FloorCell', id: string, row: number, column: number, floor: number, unit: { __typename?: 'Unit', id: string } } | null };

export type CreateUpdateSensorMutationVariables = Types.Exact<{
  input: Types.CreateUpdateSensorInput;
}>;


export type CreateUpdateSensorMutation = { __typename?: 'Mutation', createUpdateSensor: { __typename?: 'Sensor', id: string, hardwareId: string, buildingId?: string | null, unitId?: string | null, deviceName?: string | null, location?: string | null, inUse: boolean, enableAlerts: boolean, monitorWater: boolean, monitorTemperature: boolean, monitorHumidity: boolean, monitorDoor: boolean, monitorSound: boolean, monitorPower: boolean, waterSensitivity?: number | null, lowTemperatureThreshold?: number | null, highTemperatureThreshold?: number | null, lowHumidityThreshold?: number | null, highHumidityThreshold?: number | null, highDecibelThreshold?: number | null, probeType?: string | null, notes?: string | null, countPeople: boolean, countPeopleInLabel?: string | null, countPeopleOutLabel?: string | null, floorCell?: { __typename?: 'FloorCell', id: string, row: number, column: number, floor: number, unit: { __typename?: 'Unit', id: string } } | null } };

export type SensorQrLookupQueryVariables = Types.Exact<{
  qrRawValue: Types.Scalars['String'];
}>;


export type SensorQrLookupQuery = { __typename?: 'Query', sensorQrLookup: unknown };

export const SensorFragmentDoc = gql`
    fragment Sensor on Sensor {
  id
  hardwareId
  buildingId
  unitId
  deviceName
  location
  inUse
  enableAlerts
  monitorWater
  monitorTemperature
  monitorHumidity
  monitorDoor
  monitorSound
  monitorPower
  waterSensitivity
  lowTemperatureThreshold
  highTemperatureThreshold
  lowHumidityThreshold
  highHumidityThreshold
  highDecibelThreshold
  probeType
  notes
  countPeople
  countPeopleInLabel
  countPeopleOutLabel
  floorCell {
    id
    row
    column
    floor
    unit {
      id
    }
  }
}
    `;
export const CreateUpdateSensorDocument = gql`
    mutation CreateUpdateSensor($input: CreateUpdateSensorInput!) {
  createUpdateSensor(input: $input) {
    ...Sensor
  }
}
    ${SensorFragmentDoc}`;
export type CreateUpdateSensorMutationFn = Apollo.MutationFunction<CreateUpdateSensorMutation, CreateUpdateSensorMutationVariables>;

/**
 * __useCreateUpdateSensorMutation__
 *
 * To run a mutation, you first call `useCreateUpdateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateSensorMutation, { data, loading, error }] = useCreateUpdateSensorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateSensorMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateSensorMutation, CreateUpdateSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateSensorMutation, CreateUpdateSensorMutationVariables>(CreateUpdateSensorDocument, options);
      }
export type CreateUpdateSensorMutationHookResult = ReturnType<typeof useCreateUpdateSensorMutation>;
export type CreateUpdateSensorMutationResult = Apollo.MutationResult<CreateUpdateSensorMutation>;
export type CreateUpdateSensorMutationOptions = Apollo.BaseMutationOptions<CreateUpdateSensorMutation, CreateUpdateSensorMutationVariables>;
export const SensorQrLookupDocument = gql`
    query SensorQrLookup($qrRawValue: String!) {
  sensorQrLookup(qrRawValue: $qrRawValue)
}
    `;

/**
 * __useSensorQrLookupQuery__
 *
 * To run a query within a React component, call `useSensorQrLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorQrLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorQrLookupQuery({
 *   variables: {
 *      qrRawValue: // value for 'qrRawValue'
 *   },
 * });
 */
export function useSensorQrLookupQuery(baseOptions: Apollo.QueryHookOptions<SensorQrLookupQuery, SensorQrLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorQrLookupQuery, SensorQrLookupQueryVariables>(SensorQrLookupDocument, options);
      }
export function useSensorQrLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorQrLookupQuery, SensorQrLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorQrLookupQuery, SensorQrLookupQueryVariables>(SensorQrLookupDocument, options);
        }
export type SensorQrLookupQueryHookResult = ReturnType<typeof useSensorQrLookupQuery>;
export type SensorQrLookupLazyQueryHookResult = ReturnType<typeof useSensorQrLookupLazyQuery>;
export type SensorQrLookupQueryResult = Apollo.QueryResult<SensorQrLookupQuery, SensorQrLookupQueryVariables>;