import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { Box, Slider, Typography, Paper, Grid, Button, TextField, Snackbar, Alert } from '@mui/material'
import { useParams } from 'react-router-dom'
import FloorGrid from '../../organisms/FloorGrid'
import useSetNorthPage from './useSetDimensionsPage'
import { toInteger, omit } from 'lodash'
import RotatePhoneAnimation from '../../molecules/RotatePhoneAnimation'
import { atBreakpoint } from '../../../common/utils/display'

const SetDimensionsPage = ({}) => {
  const { buildingId } = useParams('buildingId')

  const [alertState, setAlertState] = useState({
    open: false,
    severity: 'success',
    alertMessage: '',
    shownFor: 2500 // time alert is shown in ms
  })

  const {
    rotation,
    setRotation,
    grid,
    resetGrid,
    building,
    buildingLoading,
    control,
    handleSubmit,
    errors,
    getValues,
    onSubmit,
    reset,
    watch
  } = useSetNorthPage({ buildingId, alertState, setAlertState })

  const handleSliderChange = (event, newValue) => {
    setRotation(newValue)
    reset({
      rotationToNorth: newValue,
      ...omit(getValues(), 'rotationToNorth')
    })
  }

  useEffect(() => {
    watch((data, { name: field, type }) => {
      if ((field === 'numFloorCellRows' || field === 'numFloorCellColumns') && type === 'change') {
        if (data['numFloorCellRows'] && data['numFloorCellColumns']) {
          resetGrid(toInteger(data['numFloorCellRows']), toInteger(data['numFloorCellColumns']))
        }
      }
    })
  }, [])

  const isCollapsed = atBreakpoint('lg')

  if (buildingLoading) return <></>
  return (
    <Paper sx={{ padding: 2 }}>
      <RotatePhoneAnimation />
      <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={3} sx={{ mb: 2 }}>
            <Box sx={{ m: 'auto', pt: 2, textAlign: 'center', minWidth: '150px', maxWidth: '250px' }}>
              <Typography variant={'h5'} sx={{ mb: 2 }}>
                Enter Dimensions
              </Typography>
              <Controller
                name='numFloorCellRows'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin='dense'
                    label='Number of Rows'
                    inputProps={{ type: 'number', min: 3, max: 15 }}
                    fullWidth
                    error={!!errors.numFloorCellRows}
                    helperText={errors.numFloorCellRows?.message}
                    disabled={false}
                  />
                )}
              />
              <Controller
                name='numFloorCellColumns'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin='dense'
                    label='Number of Columns'
                    inputProps={{ type: 'number', min: 3, max: 15 }}
                    fullWidth
                    error={!!errors.numFloorCellColumns}
                    helperText={errors.numFloorCellColumns?.message}
                    disabled={false}
                  />
                )}
              />
              {!isCollapsed && (
                <>
                  <Button sx={{ mr: 2 }} variant='contained' type='submit'>
                    Save
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      reset({
                        rotationToNorth: building.rotationToNorth,
                        numFloorCellRows: building.numFloorCellRows,
                        numFloorCellColumns: building.numFloorCellColumns
                      })
                      setRotation(building.rotationToNorth)
                      resetGrid(building.numFloorCellRows, building.numFloorCellColumns)
                    }}
                  >
                    Reset
                  </Button>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            sx={{
              alignItems: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              justifyContent: 'center',
              backgroundColor: null
            }}
          >
            <Typography variant={'h5'} sx={{ mt: 2, mb: 2 }}>
              Select Orientation From North
            </Typography>
            <Grid container sx={{ width: 200 }}>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Slider
                  aria-label='north-slider'
                  value={rotation}
                  min={-45}
                  max={45}
                  onChange={handleSliderChange}
                />
              </Grid>
              <Grid item xs={1} sx={{ paddingLeft: 2, paddingTop: 0.5 }}>
                <Typography>{rotation}°</Typography>
              </Grid>
            </Grid>
            <Typography>N</Typography>
            {/* <Box sx={{height: 100}}></Box> */}
            <FloorGrid
              grid={grid}
              currentFloor={1}
              currentUnit={{}}
              floorUnits={{}}
              rotation={rotation}
              disabled
            />
            {isCollapsed && (
              <Box sx={{ mt: 3 }}>
                <Button sx={{ mr: 2 }} variant='contained' type='submit'>
                  Save
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => {
                    reset({
                      rotationToNorth: building.rotationToNorth,
                      numFloorCellRows: building.numFloorCellRows,
                      numFloorCellColumns: building.numFloorCellColumns
                    })
                    setRotation(building.rotationToNorth)
                    resetGrid(building.numFloorCellRows, building.numFloorCellColumns)
                  }}
                >
                  Reset
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={alertState.open}
        autoHideDuration={alertState.shownFor ? alertState.shownFor : 2500}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertState.alertMessage}
        </Alert>
      </Snackbar>
    </Paper>
  )
}

export default SetDimensionsPage
