import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'

import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

import MenuDropdown from '../MenuDropdown'

import {
  MenuItem,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { TYPE_PROPERTY_ADMIN, TYPE_PROPERTY_MANAGER, TYPE_SUPER_ADMIN } from '../../../common/constants/users'

const PropertyManagerListRow = ({ propertyManager, updatePriority, deletePropertyManager, currentUser }) => {
  // State variable that indicates whether the priority is being edited
  const [editPriority, setEditPriority] = useState(false)
  const [priorityInput, setPriorityInput] = useState(`${propertyManager.priority}`)
  const [valid, setValid] = useState(true)

  const [dialogOpen, setDialogOpen] = useState(false)

  const validate = (input) => {
    // Make sure that the is an integer between 0 and 2**31-1
    return /^[0-9]+$/.test(input) && parseInt(input) >= 1 && parseInt(input) <= 2 ** 31 - 1
  }

  const handleSubmit = () => {
    if (editPriority) {
      // If currently editing priority, a button click means we submit
      if (validate(priorityInput)) {
        // Send API request to update priority
        // Update position of row in table
        updatePriority(propertyManager, parseInt(priorityInput))
        setValid(true)
      } else {
        setValid(false)
        return
      }
    }
    setEditPriority(!editPriority) // Toggle editing if input is valid or if we are not currently editing
  }

  const onEnterPressed = (event) => {
    if (event.key === 'Enter') {
      // If "Enter" is pressed, submit
      handleSubmit()
    }
  }

  const redirectEdit = () => {
    window.location.href = `/admin/users/${propertyManager.user.id}/edit`
  }

  // Returns the type of the user to be used as text
  const getTypeText = (type) => {
    if (type === TYPE_PROPERTY_MANAGER) return 'Manager'
    else if (type === TYPE_PROPERTY_ADMIN) return 'Admin'
  }

  const handleClose = () => setDialogOpen(false)

  // Property Admins can edit priority for everyone
  const userCanEdit =
    currentUser.type === TYPE_SUPER_ADMIN ||
    currentUser.id === propertyManager.user.id ||
    (currentUser.type === TYPE_PROPERTY_ADMIN && propertyManager.user.type === TYPE_PROPERTY_MANAGER)

  const userCanEditPriority =
    [TYPE_SUPER_ADMIN, TYPE_PROPERTY_ADMIN].includes(currentUser.type) ||
    currentUser.id === propertyManager.user.id

  return (
    <React.Fragment>
      <StyledTableRow hover>
        <TableCell align='left'>{propertyManager.user.firstName}</TableCell>
        <TableCell align='left'>{propertyManager.user.lastName}</TableCell>
        <TableCell align='left'>{propertyManager.user.email}</TableCell>
        <TableCell align='left'>{propertyManager.user.phoneNumber}</TableCell>
        <TableCell align='left'>{getTypeText(propertyManager.user.type)}</TableCell>
        <TableCell align='left'>
          <FormControl sx={{ width: '8em', padding: 0 }} variant='outlined'>
            <OutlinedInput
              id='priority-input'
              disabled={!editPriority}
              type='text'
              endAdornment={
                userCanEditPriority && (
                  <InputAdornment position='end'>
                    <IconButton aria-label='Edit Priority' onClick={handleSubmit} edge='end'>
                      {editPriority ? (
                        <CheckCircleRoundedIcon className='submit-priority' />
                      ) : (
                        <ModeEditRoundedIcon className='edit-priority' />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }
              defaultValue={propertyManager.priority}
              autoFocus={true}
              error={!valid}
              onChange={(event) => setPriorityInput(event.target.value)}
              onKeyDown={onEnterPressed}
              sx={{ height: '3em' }}
            />
            {!valid && <FormHelperText error>Enter a valid priority</FormHelperText>}
          </FormControl>
        </TableCell>
        <TableCell align='right' style={{ paddingBottom: 0, paddingTop: 0 }}>
          {/* SuperAdmins can edit anyone's priority. PropertyAdmins can edit only their own priority and PropertyManagers priorities*/}
          {userCanEdit && (
            <MenuDropdown>
              <MenuItem onClick={redirectEdit}>Edit</MenuItem>
              {[TYPE_SUPER_ADMIN, TYPE_PROPERTY_ADMIN].includes(currentUser.type) && (
                <MenuItem className='property-manager-remove' onClick={() => setDialogOpen(true)}>
                  Remove from Building
                </MenuItem>
              )}
            </MenuDropdown>
          )}
        </TableCell>
      </StyledTableRow>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Remove user from building?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to remove {propertyManager.user.firstName} {propertyManager.user.lastName}{' '}
            from this building?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className='confirm-removal'
            variant='contained'
            onClick={() => {
              deletePropertyManager(propertyManager.id)
              handleClose()
            }}
            autoFocus
          >
            Confirm Removal
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default PropertyManagerListRow
