import { useState } from 'react'
import { useGetBuildingDimsQuery } from '../ZoneCreateEditPage/GraphQl/gql.generated'
import { useUpdateBuildingMutation } from './GraphQl/gql.generated'
import { get } from 'lodash'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup
  .object({
    rotationToNorth: yup
      .number()
      .typeError('Orientation angle must be between -45° and 45°.')
      .integer('Orientation angle must be a whole number between -45° and 45°.')
      .min(-45, 'Orientation angle must be between -45° and 45°.')
      .max(45, 'Orientation angle must be between -45° and 45°.')
      .nullable(),
    numFloorCellRows: yup
      .number()
      .typeError('Number of rows must be between 3 and 15.')
      .integer('Number of rows must be a whole number between 3 and 15.')
      .min(3, 'Number of rows must be between 3 and 15.')
      .max(15, 'Number of rows must be between 3 and 15.')
      .nullable(),
    numFloorCellColumns: yup
      .number()
      .typeError('Number of columns must be between 3 and 15.')
      .integer('Number of columns must be a whole number between 3 and 15.')
      .min(3, 'Number of columns must be between 3 and 15.')
      .max(15, 'Number of columns must be between 3 and 15.')
      .nullable()
  })
  .required()

const useSetDimensionsPage = ({ buildingId, alertState, setAlertState }) => {
  const [rotation, setRotation] = useState(0)
  const [grid, setGrid] = useState([[]])

  const resetGrid = (num_rows, num_columns) => {
    const newGrid = new Array(num_rows).fill(null).map(() => new Array(num_columns).fill(null))
    for (let row = 0; row < num_rows; row++) {
      for (let col = 0; col < num_columns; col++) {
        newGrid[row][col] = {
          unitId: null,
          props: {
            disabled: true
          }
        }
      }
    }
    setGrid(newGrid)
  }

  const defaultValues = {
    rotationToNorth: 0,
    numFloorCellRows: 10,
    numFloorCellColumns: 10
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const { data: buildingData, loading: buildingLoading } = useGetBuildingDimsQuery({
    variables: {
      id: buildingId
    },
    onCompleted: (data) => {
      const building = data.getBuilding

      const num_rows = building.numFloorCellRows
      const num_columns = building.numFloorCellColumns
      resetGrid(num_rows, num_columns)
      setRotation(building.rotationToNorth)
      reset({
        rotationToNorth: building.rotationToNorth,
        numFloorCellRows: building.numFloorCellRows,
        numFloorCellColumns: building.numFloorCellColumns
      })
    }
  })

  const building = get(buildingData, ['getBuilding'], {})

  const [updateBuilding] = useUpdateBuildingMutation()
  const onSubmit = (input) => {
    updateBuilding({
      variables: {
        input: {
          id: buildingId,
          ...input
        }
      },
      onCompleted: (data) => {
        const orientation = data.updateBuilding
        reset({
          rotationToNorth: orientation.rotationToNorth,
          numFloorCellRows: orientation.numFloorCellRows,
          numFloorCellColumns: orientation.numFloorCellColumns
        })
        setAlertState({
          open: true,
          severity: 'success',
          alertMessage: 'Building orientation and dimensions were updated successfully.',
          shownFor: alertState.shownFor
        })
      },
      onError: (error) => {
        setAlertState({
          open: true,
          severity: 'error',
          alertMessage: error.graphQLErrors[0].message,
          shownFor: alertState.shownFor
        })
      },
      refetchQueries: ['GetBuildingDims']
    })
  }

  return {
    rotation,
    setRotation,
    grid,
    resetGrid,
    building,
    buildingLoading,
    control,
    handleSubmit,
    errors,
    getValues,
    onSubmit,
    reset,
    watch
  }
}

export default useSetDimensionsPage
