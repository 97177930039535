import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useCreateUpdateSensorMutation } from './GraphQl/gql.generated'
import { get, isEmpty, isNil, toInteger } from 'lodash'
import { isMobile } from '../../../common/utils/display'
import { format } from 'date-fns'
import {
  useGetSensorLazyQuery,
  useGetSensorsPageLocationPhotoLazyQuery
} from '../SensorPage/GraphQl/gql.generated'
import { useFetchUnitsLazyQuery } from '../dashboards/components/DashboardFiltersPanel/gql.generated'
import {
  SENSOR_CAPABILITY_DOOR,
  SENSOR_CAPABILITY_LEAK,
  SENSOR_CAPABILITY_MOTION
} from '../../../common/constants/sensors'

const useSensorCreateEdit = ({
  sensorId,
  buildingId,
  setShowUnitModal,
  setSensorCapabilities,
  setDoorHoursValues,
  setMotionHoursValues,
  setSensorPurpose,
  setImageSrc,
  sensorRow,
  setSensorRow,
  sensorCol,
  setSensorCol,
  movingSensor
}) => {
  const [tempRange, setTempRange] = useState([5, 45])
  const [humidityRange, setHumidityRange] = useState([0, 100])
  const [waterSensitivity, setWaterSensitivity] = useState(0)
  const [units, setUnits] = useState([])
  const [createUpdateSensor, { loading: savingSensor }] = useCreateUpdateSensorMutation()
  const [getSensorData, { data: sensorData, loading: sensorLoading }] = useGetSensorLazyQuery({
    variables: { sensorId }
  })
  // Get Sensor Location Photos
  const [getSensorLocationPhoto, { data: locationPhotoData }] = useGetSensorsPageLocationPhotoLazyQuery()
  const [getUnits, { loading: unitsLoading }] = useFetchUnitsLazyQuery({
    variables: {
      buildingIds: [buildingId],
      pagination: {
        per: 300
      }
    }
  })
  const displayIsMobile = isMobile()
  const updateKeys = [
    'hardwareId',
    'deviceName',
    'location',
    'inUse',
    'enableAlerts',
    'monitorWater',
    'monitorTemperature',
    'monitorHumidity',
    'monitorDoor',
    'monitorMotion',
    'monitorSound',
    'monitorPower',
    'waterSensitivity',
    'lowTemperatureThreshold',
    'highTemperatureThreshold',
    'lowHumidityThreshold',
    'highHumidityThreshold',
    'highDecibelThreshold',
    'probeType',
    'notes',
    'image',
    'countPeople',
    'countPeopleInLabel',
    'countPeopleOutLabel'
  ]

  const sensor = get(sensorData, ['getSensor'], {})

  const schema = yup
    .object({
      hardwareId: yup.string().required('Hardware ID is a required field'),
      deviceName: yup.string().required('Make/Model is a required field'),
      location: yup.string().required('Location is a required field'),
      unitId: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required('Zone is required')
    })
    .required()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hardwareId: '',
      deviceName: '',
      location: '',
      unitId: '',
      inUse: true,
      enableAlerts: false,
      monitorWater: false,
      monitorTemperature: false,
      monitorHumidity: false,
      monitorDoor: false,
      monitorMotion: false,
      monitorPower: false,
      monitorSound: false,
      waterSensitivity: null,
      lowTemperatureThreshold: null,
      highTemperatureThreshold: null,
      lowHumidityThreshold: null,
      highHumidityThreshold: null,
      probeType: '',
      notes: '',
      image: null,
      countPeople: false,
      countPeopleInLabel: '',
      countPeopleOutLabel: ''
    }
  })

  const handleAddUnit = (newUnit) => {
    setUnits([...units, newUnit])
    setValue('unitId', newUnit.id, { shouldValidate: true })
    setShowUnitModal(false)
  }

  const handleSensorPurposeChange = (purpose) => {
    setSensorPurpose(purpose)
    if (purpose === SENSOR_CAPABILITY_LEAK) {
      setValue('monitorWater', true)
      setValue('monitorDoor', false)
      setValue('monitorPower', false)
      setValue('monitorSound', false)
      setValue('monitorMotion', false)
      setValue('probeType', 'pin')
    } else if (purpose === SENSOR_CAPABILITY_DOOR) {
      setValue('monitorWater', false)
      setValue('monitorDoor', true)
      setValue('monitorPower', false)
      setValue('monitorSound', false)
      setValue('monitorMotion', false)
      setValue('probeType', SENSOR_CAPABILITY_DOOR)
    } else if (purpose === SENSOR_CAPABILITY_MOTION) {
      setValue('monitorWater', false)
      setValue('monitorDoor', false)
      setValue('monitorPower', false)
      setValue('monitorSound', false)
      setValue('monitorMotion', true)
      setValue('probeType', '')
    } else {
      setValue('monitorWater', false)
      setValue('monitorDoor', false)
      setValue('monitorMotion', false)
      setValue('probeType', '')
    }
  }

  const handleSensorCreateUpdateSubmit = (
    input,
    setSubmitError,
    buildingId,
    waterEnabled,
    tempEnabled,
    humidityEnabled,
    doorEnabled,
    doorHoursValues,
    motionEnabled,
    motionHoursValues
  ) => {
    setSubmitError('')
    let submitInput = { ...input, ...{ buildingId: buildingId } }

    if (waterEnabled) {
      submitInput.waterSensitivity = waterSensitivity
    }

    if (tempEnabled) {
      submitInput.lowTemperatureThreshold = tempRange[0]
      submitInput.highTemperatureThreshold = tempRange[1]
    }

    if (humidityEnabled) {
      submitInput.lowHumidityThreshold = humidityRange[0]
      submitInput.highHumidityThreshold = humidityRange[1]
    }

    if (doorEnabled && doorHoursValues.doorAlertHoursEnabled) {
      submitInput = { ...submitInput, ...doorHoursValues }
      submitInput.doorAlertHoursStart = format(
        doorHoursValues.doorAlertHoursStart,
        "yyyy-MM-dd'T'HH:mm:00.000'Z'"
      )
      submitInput.doorAlertHoursEnd = format(
        doorHoursValues.doorAlertHoursEnd,
        "yyyy-MM-dd'T'HH:mm:00.000'Z'"
      )
    }

    if (motionEnabled && motionHoursValues.motionAlertHoursEnabled) {
      submitInput = { ...submitInput, ...motionHoursValues }
      submitInput.motionAlertHoursStart = format(
        motionHoursValues.motionAlertHoursStart,
        "yyyy-MM-dd'T'HH:mm:00.000'Z'"
      )
      submitInput.motionAlertHoursEnd = format(
        motionHoursValues.motionAlertHoursEnd,
        "yyyy-MM-dd'T'HH:mm:00.000'Z'"
      )
    }

    if (submitInput.monitorSound && !isEmpty(submitInput.highDecibelThreshold)) {
      submitInput.highDecibelThreshold = parseInt(submitInput.highDecibelThreshold)
    }

    // TODO: Finish this update so backend knows if we are provisioning a new sensor or just updating existing
    // if (sensorId)
    //   submitInput.id = sensorId

    createUpdateSensor({
      variables: {
        input: {
          input: {
            ...submitInput,
            floorCellRow: sensorRow,
            floorCellColumn: sensorCol,
            editFloorcell: movingSensor
          }
        }
      },
      onCompleted: (data) => {
        const createdSensor = get(data, ['createUpdateSensor'], {})
        window.location.href = `/sensors/${
          createdSensor.id
        }?collapse_details=${displayIsMobile}&building_id=${buildingId.toString()}`
      },
      onError: (error) => setSubmitError(error.message)
    })
  }

  const bulkSetValues = (object, setValue, keys, options = {}) => {
    keys.forEach((key) => {
      if (object.hasOwnProperty(key)) setValue(key, object[key], options)
    })

    if (object.hasOwnProperty('lowTemperatureThreshold') && !isNil(object.lowTemperatureThreshold)) {
      setTempRange([object.lowTemperatureThreshold, object.highTemperatureThreshold])
    }

    if (object.hasOwnProperty('lowHumidityThreshold') && !isNil(object.lowHumidityThreshold)) {
      setHumidityRange([object.lowHumidityThreshold, object.highHumidityThreshold])
    }
  }

  useEffect(() => {
    getUnits().then((responseData) => {
      setUnits(get(responseData, ['data', 'fetchUnits'], []))
      if (sensorId) {
        getSensorData({ variables: { id: sensorId, includeCapabilities: true } }).then((sensorData) => {
          const sensor = get(sensorData, ['data', 'getSensor'], {})
          // For now we do not support onboarding for light sensors as there is no use case for it currently
          const capabilities = sensor.deviceDetails
            ? sensor.deviceDetails.capabilities.filter((c) => c !== 'light')
            : []
          capabilities.push('other')
          setSensorCapabilities(capabilities)

          setValue('unitId', toInteger(sensor.unit.id), { shouldValidate: true })
          if (sensor.monitorWater) {
            handleSensorPurposeChange(SENSOR_CAPABILITY_LEAK)
            setWaterSensitivity(sensor.waterSensitivity)
          } else if (sensor.monitorDoor) {
            handleSensorPurposeChange(SENSOR_CAPABILITY_DOOR)
            setDoorHoursValues({
              doorAlertHoursEnabled: sensor.doorAlertHoursEnabled,
              doorAlertDaysArr: sensor.doorAlertDaysArr || [],
              doorAlertHoursEnd: sensor.doorAlertEndTime,
              doorAlertHoursStart: sensor.doorAlertStartTime,
              doorAlertTimezone: sensor.doorAlertTimezone,
              doorAlertWeekendHolidays: sensor.doorAlertWeekendHolidays
            })
          } else if (sensor.monitorMotion) {
            handleSensorPurposeChange(SENSOR_CAPABILITY_MOTION)
            setMotionHoursValues({
              motionAlertHoursEnabled: sensor.motionAlertHoursEnabled,
              motionAlertDaysArr: sensor.motionAlertDaysArr || [],
              motionAlertHoursEnd: sensor.motionAlertEndTime,
              motionAlertHoursStart: sensor.motionAlertStartTime,
              motionAlertTimezone: sensor.motionAlertTimezone,
              motionAlertWeekendHolidays: sensor.motionAlertWeekendHolidays
            })
          } else {
            handleSensorPurposeChange('other')
          }

          bulkSetValues(sensor, setValue, updateKeys, { shouldValidate: true })

          if (sensor.floorCell) {
            setSensorRow(sensor.floorCell.row)
            setSensorCol(sensor.floorCell.column)
          }
        })

        getSensorLocationPhoto({ variables: { id: sensorId } }).then((locationPhotoData) => {
          const sensorLocationPhoto = get(
            locationPhotoData,
            ['data', 'getSensor', 'sensorLocationPhotoFull'],
            null
          )
          setImageSrc(sensorLocationPhoto)
        })
      }
    })
  }, [])

  return {
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    handleSensorCreateUpdateSubmit,
    handleSensorPurposeChange,
    tempRange,
    humidityRange,
    waterSensitivity,
    setTempRange,
    setHumidityRange,
    setWaterSensitivity,
    savingSensor,
    units,
    unitsLoading,
    handleAddUnit,
    sensor,
    sensorLoading
  }
}
export default useSensorCreateEdit
