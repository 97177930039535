import React from 'react'
import { Grid } from '@mui/material'

const SensorShowInformationRow = ({ label, value, sx = {} }) => {
  return (
    <Grid container direction='row' sx={sx}>
      <Grid item xs={12}>
        <label className='text-label'>{label}:</label>
        <span className='text-value'>{value}</span>
      </Grid>
    </Grid>
  )
}

export default SensorShowInformationRow
