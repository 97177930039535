import React, { useEffect, useState } from 'react'
import { useFetchFloorCellsLazyQuery } from './GraphQl/gql.generated'
import { idxToColor } from '../../../common/utils/floorLayouts'

const useFloorLayout = ({ buildingId, building, unitId, floor, setFloorUnits }) => {
  const num_rows = building.numFloorCellRows
  const num_columns = building.numFloorCellColumns

  const [grid, setGrid] = useState([[]])
  const [fetchFloorCells, { data: floorCellsData, loading: floorCellsLoading }] = useFetchFloorCellsLazyQuery(
    {
      variables: {
        buildingId,
        floor
      },
      onCompleted: (data) => {
        const floorCells = data.fetchFloorCells
        const floorUnits = {}
        const newGrid = new Array(num_rows).fill(null).map(() => new Array(num_columns).fill(null))
        let unitIdx = -1
        for (let cell of floorCells) {
          if (!floorUnits[cell.unit.id]) {
            floorUnits[cell.unit.id] = { unit: cell.unit, idx: ++unitIdx }
          }
          newGrid[cell.row][cell.column] = {
            unitId: cell.unit.id,
            props: {
              clickable: cell.unit.id === unitId,
              toggled: true,
              toggledBgColor: idxToColor(floorUnits[cell.unit.id].idx),
              disabled: false
            }
          }
        }
        for (let row = 0; row < num_rows; row++) {
          for (let col = 0; col < num_columns; col++) {
            // We now want to fill out the props of the empty cells
            if (newGrid[row][col] === null) {
              // By default, all empty cells are clickable, not toggled, and not disabled.
              // For information on what all of these mean, please check out the documentation for FloorCell
              newGrid[row][col] = {
                unitId: null,
                props: {
                  clickable: true,
                  toggled: false,
                  toggledBgColor: floorUnits[unitId]?.idx >= 0 ? idxToColor(floorUnits[unitId].idx) : 'red',
                  disabled: false
                }
              }
            }
          }
        }
        setGrid(newGrid)
        setFloorUnits(floorUnits)
      },
      fetchPolicy: 'network-only',
      defaultOptions: {
        notifyOnNetworkStatusChange: true
      }
    }
  )

  const onCellClick = (grid, row, col, floorCellElement) => {
    // cell was clicked
    //   - if unit assigned, remove it.
    //   - if not, set it to current unitId if editing or -1 if adding new unit
    grid[row][col].props.toggled = !grid[row][col].props.toggled
    grid[row][col].unitId = grid[row][col].unitId ? null : unitId ?? -1
    if (grid[row][col].props.toggled) {
      floorCellElement.style.backgroundColor = grid[row][col].props.toggledBgColor
    } else floorCellElement.style.removeProperty('background-color')
  }

  useEffect(() => {
    fetchFloorCells()
  }, [floor])

  return { grid, floorCellsData: floorCellsData?.fetchFloorCells, floorCellsLoading, onCellClick }
}

export default useFloorLayout
