import React, { useEffect, useRef } from 'react'
import FloorCell from '../../molecules/FloorCell'
import { Box } from '@mui/material'

import('../../../common/theme/stylesheets/floorLayouts.css')

const FloorGrid = ({ grid, callback, rotation = 0 }) => {
  var isMouseDown = useRef(false)

  const numColumns = grid[0].length

  const fitContainer = () => {
    const container = document.getElementById('floorgrid-container')
    const table = document.getElementById('floorgrid-table')

    if (table) {
      let boundingRect = table.getBoundingClientRect()

      container.style.height = `${Math.ceil(boundingRect.height)}px`
      container.style.maxHeight = '100%'
    }
  }

  useEffect(() => {
    window.addEventListener('resize', fitContainer)
  }, [])

  useEffect(() => {
    fitContainer()
  }, [grid, rotation])

  return (
    <Box
      id='floorgrid-container'
      sx={{
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'visible',
        backgroundColor: null
      }}
    >
      <table
        id='floorgrid-table'
        className='floorgrid'
        style={{ transform: `rotate(${rotation}deg)`, transformOrigin: 'center' }}
      >
        <tbody
          onMouseLeave={() => (isMouseDown.current = false)}
          onMouseDown={(event) => {
            event.preventDefault()
            isMouseDown.current = true
          }}
          onMouseUp={(event) => {
            event.preventDefault()
            isMouseDown.current = false
          }}
        >
          {grid.map((_, row) => (
            <tr key={row}>
              {grid[row].map((value, col) => (
                <FloorCell
                  grid={grid}
                  key={row * numColumns + col}
                  row={row}
                  col={col}
                  isMouseDown={isMouseDown}
                  callback={callback}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  )
}

export default FloorGrid
