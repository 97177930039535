import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  Grid,
  Stack,
  Alert,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import SquareIcon from '@mui/icons-material/Square'
import { useGetBuildingDimsQuery } from './GraphQl/gql.generated'
import useZoneCreateEditPage from './useZoneCreateEditPage'
import { useParams } from 'react-router-dom'
import FloorGrid from '../../organisms/FloorGrid'
import { toInteger } from 'lodash'
import useFloorLayout from './useFloorLayout'
import { DISPLAY_ZONE_TYPE } from '../../../common/constants/units'
import { idxToColor } from '../../../common/utils/floorLayouts'
import { useGetCurrentUserTypeQuery } from '../PropertyManagersPage/GraphQl/gql.generated'
import { TYPE_SUPER_ADMIN } from '../../../common/constants/users'
import { get } from 'lodash'
import RotatePhoneAnimation from '../../molecules/RotatePhoneAnimation'
import { atBreakpoint } from '../../../common/utils/display'

const ZoneCreateEditPage = () => {
  const { unitId } = useParams('unitId')
  const { buildingId } = useParams('buildingId')

  const [floor, setFloor] = useState(1)
  const [floorUnits, setFloorUnits] = useState({})

  const { data: buildingData, loading: buildingLoading } = useGetBuildingDimsQuery({
    variables: {
      id: buildingId
    }
  })
  const building = get(buildingData, ['getBuilding'], {})

  const { grid, floorCellsData, floorCellsLoading, onCellClick } = useFloorLayout({
    buildingId,
    building,
    unitId,
    floor,
    setFloorUnits
  })

  // Get current user type
  const { data: userData } = useGetCurrentUserTypeQuery()
  const currentUserType = get(userData, ['getCurrentUser', 'type'], '')

  const [alertState, setAlertState] = useState({
    open: false,
    severity: 'success',
    alertMessage: '',
    shownFor: 2500 // time alert is shown in ms
  })

  const { handleSubmit, control, errors, onSubmit, watch, localUnit, zoneTypes } = useZoneCreateEditPage({
    context: unitId ? 'edit' : 'create',
    unitId,
    buildingId,
    setFloor,
    grid,
    alertState,
    setAlertState,
    floorCellsData
  })

  const [dialogOpen, setDialogOpen] = useState(false)
  const [totalAttachedSensors, setTotalAttachedSensors] = useState(0)
  const getTotalAttachedSensors = () => {
    let totalAttachedSensors = 0
    for (let cell of floorCellsData) {
      let row = cell.row,
        column = cell.column
      if (
        parseInt(cell.unit.id) === parseInt(unitId) &&
        cell.numAttachedSensors > 0 &&
        grid[row][column].unitId === null
      ) {
        totalAttachedSensors += cell.numAttachedSensors
      }
    }
    return totalAttachedSensors
  }
  const handleDialogClose = () => {
    setTotalAttachedSensors(0)
    setDialogOpen(false)
  }
  const handleDialogConfirm = () => {
    handleDialogClose()
    const submitButton = document.getElementById('hidden-submit-button')
    submitButton.click()
  }
  const handleClickSave = () => {
    const attachedSensors = getTotalAttachedSensors()
    if (attachedSensors > 0) {
      setTotalAttachedSensors(attachedSensors)
      setDialogOpen(true)
    } else {
      const submitButton = document.getElementById('hidden-submit-button')
      submitButton.click()
    }
  }

  useEffect(() => {
    watch((data, { name: field, type }) => {
      if (field === 'floor' && type === 'change') {
        if (data[field]) setFloor(toInteger(data[field]))
      }
    })
  }, [])

  const isCollapsed = atBreakpoint('xl')

  return (
    <Paper sx={{ paddingBottom: '1em' }}>
      <Box id='zoneForm' component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={1} justifyContent='center'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={3} sx={{ margin: '1em' }}>
            <Box sx={{ m: 'auto', pt: 2, textAlign: 'center', minWidth: '350px', maxWidth: '500px' }}>
              <RotatePhoneAnimation sx={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: 3 }} />
              <Typography variant={'h5'} sx={{ mb: 2 }}>
                Zone Details
              </Typography>
              <Controller
                name='number'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin='dense'
                    label={building.buildingType === 'commercial' ? 'Zone Name' : 'Unit Number'}
                    type='text'
                    fullWidth
                    error={!!errors.number}
                    helperText={errors.number?.message}
                  />
                )}
              />
              <Controller
                name='zoneType'
                control={control}
                render={({ field }) => (
                  <TextField
                    className='zoneType-dropdown'
                    {...field}
                    label={'Zone Type'}
                    margin='dense'
                    sx={{
                      textAlign: 'left',
                      '& .MuiSelect-select': {
                        alignItems: 'left'
                      }
                    }}
                    fullWidth
                    select
                    SelectProps={{
                      MenuProps: {
                        classes: { paper: 'zoneType-items' },
                        style: { maxHeight: '40%' },
                        disableScrollLock: true
                      }
                    }}
                    error={!!errors.zoneType}
                    helperText={errors.zoneType?.message}
                  >
                    <MenuItem key='' value=''>
                      None
                    </MenuItem>
                    {zoneTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {DISPLAY_ZONE_TYPE[type]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name='alertEscalationMinutes'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin='dense'
                    label='Minutes Between Alert Escalation'
                    inputProps={{ type: 'number' }}
                    fullWidth
                    error={!!errors.alertEscalationMinutes}
                    helperText={errors.alertEscalationMinutes?.message}
                  />
                )}
              />
              <Controller
                name='floor'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin='dense'
                    label='Floor Number'
                    inputProps={{ type: 'number' }}
                    fullWidth
                    error={!!errors.floor}
                    helperText={errors.floor?.message}
                    disabled={!!unitId}
                  />
                )}
              />
              {!isCollapsed && (
                <Box>
                  <Button sx={{ mr: 2 }} variant='contained' onClick={handleClickSave}>
                    Save
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      window.location.href = `/units?building_id=${buildingId}`
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={0} sm={0} md={0} lg={0} xl={1} />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={7} sx={{ mt: '1em', mb: '1em' }}>
            {currentUserType === TYPE_SUPER_ADMIN && (
              <Stack
                direction='column'
                spacing={2}
                sx={{ textAlign: 'center', justifyContent: 'center', maxWidth: '100%', maxHeight: '100%' }}
              >
                <Typography variant='h6'>Floor Layout</Typography>
                <Typography variant='h9'>North</Typography>
                {!floorCellsLoading && (
                  <>
                    <FloorGrid grid={grid} callback={onCellClick} rotation={building.rotationToNorth} />
                    <Grid container columnSpacing={1} rowGap={1} sx={{ justifyContent: 'center' }}>
                      {Object.keys(floorUnits).map((unitId) => (
                        <Grid item xs={2} key={unitId} sx={{ margin: '0.25em' }}>
                          <SquareIcon sx={{ color: idxToColor(floorUnits[unitId].idx) }} />
                          <Typography>{floorUnits[unitId].unit.number}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                {floorCellsLoading && (
                  <div style={{ display: 'block' }}>
                    <CircularProgress sx={{ marginTop: '5em' }} />
                  </div>
                )}
                {isCollapsed && (
                  <Box>
                    <Button onClick={handleClickSave} sx={{ mr: 2 }} variant='contained'>
                      Save
                    </Button>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        window.location.href = `/units?building_id=${buildingId}`
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </Stack>
            )}
          </Grid>
        </Grid>
        <Button id='hidden-submit-button' hidden type='submit' />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={alertState.open}
        autoHideDuration={alertState.shownFor ? alertState.shownFor : 2500}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertState.alertMessage}
        </Alert>
      </Snackbar>
      <Dialog open={dialogOpen} onClose={handleDialogClose} aria-describedby='alert-dialog-slide-description'>
        <DialogTitle>Warning: Some Sensors Are Attached</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            The floor cells you deselected have {totalAttachedSensors} sensor
            {totalAttachedSensors > 1 ? 's' : ''} attached to them. Saving will clear the location of th
            {totalAttachedSensors > 1 ? 'ose' : 'at'} sensor{totalAttachedSensors > 1 ? 's' : ''}. Are you
            sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm} variant='contained'>
            Save Anyway
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default ZoneCreateEditPage
