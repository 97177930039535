import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSensorQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  hardwareId?: Types.InputMaybe<Types.Scalars['String']>;
  includeCapabilities?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetSensorQuery = { __typename?: 'Query', getSensor: { __typename?: 'Sensor', id: string, deviceName?: string | null, location?: string | null, notes?: string | null, hardwareId: string, probeType?: string | null, inUse: boolean, isMuted?: boolean | null, muteUntil?: unknown | null, enableAlerts: boolean, monitoringTypes?: Array<string> | null, monitorButton: boolean, monitorDoor: boolean, monitorMotion: boolean, monitorHumidity: boolean, monitorPower: boolean, monitorTemperature: boolean, monitorWater: boolean, monitorSound: boolean, countPeople: boolean, waterSensitivity?: number | null, highTemperatureThreshold?: number | null, lowTemperatureThreshold?: number | null, highHumidityThreshold?: number | null, lowHumidityThreshold?: number | null, doorAlertDaysArr?: Array<number> | null, doorAlertHoursEnabled?: boolean | null, doorAlertStartTime?: string | null, doorAlertEndTime?: string | null, doorAlertWeekendHolidays?: boolean | null, doorAlertTimezone?: string | null, motionAlertDaysArr?: Array<number> | null, motionAlertHoursEnabled?: boolean | null, motionAlertStartTime?: string | null, motionAlertEndTime?: string | null, motionAlertWeekendHolidays?: boolean | null, motionAlertTimezone?: string | null, highDecibelThreshold?: number | null, countPeopleInLabel?: string | null, countPeopleOutLabel?: string | null, deviceDetails?: unknown | null, building?: { __typename?: 'Building', id: string, name?: string | null, buildingType: string, numFloorCellRows: number, numFloorCellColumns: number, rotationToNorth: number } | null, unit?: { __typename?: 'Unit', id: string, number?: string | null, floor: number } | null, floorCell?: { __typename?: 'FloorCell', id: string, row: number, column: number, floor: number } | null } };

export type GetSensorsPageLocationPhotoQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  hardwareId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetSensorsPageLocationPhotoQuery = { __typename?: 'Query', getSensor: { __typename?: 'Sensor', id: string, sensorLocationPhotoFull?: string | null } };

export type GetCurrentUserForSensorsPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentUserForSensorsPageQuery = { __typename?: 'Query', getCurrentUser: { __typename?: 'User', id: string, type: string } };


export const GetSensorDocument = gql`
    query GetSensor($id: ID, $hardwareId: String, $includeCapabilities: Boolean) {
  getSensor(
    id: $id
    hardwareId: $hardwareId
    includeCapabilities: $includeCapabilities
  ) {
    id
    deviceName
    location
    notes
    hardwareId
    probeType
    inUse
    isMuted
    muteUntil
    enableAlerts
    monitoringTypes
    monitorButton
    monitorDoor
    monitorMotion
    monitorHumidity
    monitorPower
    monitorTemperature
    monitorWater
    monitorSound
    countPeople
    waterSensitivity
    highTemperatureThreshold
    lowTemperatureThreshold
    highHumidityThreshold
    lowHumidityThreshold
    doorAlertDaysArr
    doorAlertHoursEnabled
    doorAlertStartTime
    doorAlertEndTime
    doorAlertWeekendHolidays
    doorAlertTimezone
    motionAlertDaysArr
    motionAlertHoursEnabled
    motionAlertStartTime
    motionAlertEndTime
    motionAlertWeekendHolidays
    motionAlertTimezone
    highDecibelThreshold
    countPeopleInLabel
    countPeopleOutLabel
    building {
      id
      name
      buildingType
      numFloorCellRows
      numFloorCellColumns
      rotationToNorth
    }
    unit {
      id
      number
      floor
    }
    floorCell {
      id
      row
      column
      floor
    }
    deviceDetails
  }
}
    `;

/**
 * __useGetSensorQuery__
 *
 * To run a query within a React component, call `useGetSensorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hardwareId: // value for 'hardwareId'
 *      includeCapabilities: // value for 'includeCapabilities'
 *   },
 * });
 */
export function useGetSensorQuery(baseOptions?: Apollo.QueryHookOptions<GetSensorQuery, GetSensorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensorQuery, GetSensorQueryVariables>(GetSensorDocument, options);
      }
export function useGetSensorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensorQuery, GetSensorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensorQuery, GetSensorQueryVariables>(GetSensorDocument, options);
        }
export type GetSensorQueryHookResult = ReturnType<typeof useGetSensorQuery>;
export type GetSensorLazyQueryHookResult = ReturnType<typeof useGetSensorLazyQuery>;
export type GetSensorQueryResult = Apollo.QueryResult<GetSensorQuery, GetSensorQueryVariables>;
export const GetSensorsPageLocationPhotoDocument = gql`
    query GetSensorsPageLocationPhoto($id: ID, $hardwareId: String) {
  getSensor(id: $id, hardwareId: $hardwareId) {
    id
    sensorLocationPhotoFull
  }
}
    `;

/**
 * __useGetSensorsPageLocationPhotoQuery__
 *
 * To run a query within a React component, call `useGetSensorsPageLocationPhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorsPageLocationPhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorsPageLocationPhotoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hardwareId: // value for 'hardwareId'
 *   },
 * });
 */
export function useGetSensorsPageLocationPhotoQuery(baseOptions?: Apollo.QueryHookOptions<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>(GetSensorsPageLocationPhotoDocument, options);
      }
export function useGetSensorsPageLocationPhotoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>(GetSensorsPageLocationPhotoDocument, options);
        }
export type GetSensorsPageLocationPhotoQueryHookResult = ReturnType<typeof useGetSensorsPageLocationPhotoQuery>;
export type GetSensorsPageLocationPhotoLazyQueryHookResult = ReturnType<typeof useGetSensorsPageLocationPhotoLazyQuery>;
export type GetSensorsPageLocationPhotoQueryResult = Apollo.QueryResult<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>;
export const GetCurrentUserForSensorsPageDocument = gql`
    query GetCurrentUserForSensorsPage {
  getCurrentUser {
    id
    type
  }
}
    `;

/**
 * __useGetCurrentUserForSensorsPageQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserForSensorsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserForSensorsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserForSensorsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserForSensorsPageQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>(GetCurrentUserForSensorsPageDocument, options);
      }
export function useGetCurrentUserForSensorsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>(GetCurrentUserForSensorsPageDocument, options);
        }
export type GetCurrentUserForSensorsPageQueryHookResult = ReturnType<typeof useGetCurrentUserForSensorsPageQuery>;
export type GetCurrentUserForSensorsPageLazyQueryHookResult = ReturnType<typeof useGetCurrentUserForSensorsPageLazyQuery>;
export type GetCurrentUserForSensorsPageQueryResult = Apollo.QueryResult<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>;