import { format, isValid, isDate, setDay } from 'date-fns'

export const fullDateTimeFormat = (date) => {
  const resultDate = validateDate(date)
  return resultDate === false ? date : format(resultDate, 'yyyy-MM-dd h:mm:ss aa')
}
export const dateFormat = (date) => {
  const resultDate = validateDate(date)
  return resultDate === false ? date : format(resultDate, 'yyyy-MM-dd')
}

// example usage: Sensor's door alerting days is a string that looks like "0, 1, 2, 3, 4" which would represent all weekdays
// assumption is Monday = 0, Sunday = 6
export const getDaysFromArray = (dayIndexes) => {
  const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const allDays = [0, 1, 2, 3, 4, 5, 6].every((index) => dayIndexes.includes(index))
  if (allDays) return 'All Days'

  const allWeekdays = [0, 1, 2, 3, 4].every((index) => dayIndexes.includes(index))
  if (allWeekdays && dayIndexes.length === 5) return 'Weekdays'

  const allWeekend = [5, 6].every((index) => dayIndexes.includes(index))
  if (allWeekend && dayIndexes.length === 2) return 'Weekends'

  const selectedDays = dayIndexes.map((index) => daysOfTheWeek[index])
  return selectedDays.join(', ')
}

export const validateDate = (date) => {
  if (date === null || date === undefined) return false
  const resultDate = isDate(date) ? date : new Date(date)
  return !isValid(resultDate) ? false : resultDate
}

export const getWorkdaysBetween = (startTimestamp, endTimestamp) => {
  let workingDays = 0
  const startDate = new Date(startTimestamp)
  const endDate = new Date(endTimestamp)

  while (startDate <= endDate) {
    // getDay returns the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    const dayOfWeek = startDate.getDay()
    // Check if it is a weekday
    if (dayOfWeek > 0 && dayOfWeek < 6) {
      workingDays++
    }
    // Move to the next day
    startDate.setDate(startDate.getDate() + 1)
  }
  return workingDays
}

export const getWeekdayFromNumber = (dayNumber) => {
  // Create a stand-in date (2022-01-02 is chosen because it was a Sunday)
  const baseDate = new Date(2022, 0, 2)
  const targetDate = setDay(baseDate, dayNumber)
  // Format the date to return only the weekday name
  return format(targetDate, 'eeee')
}

export const parseTimeToNewDate = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number)
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, 0) // Combine with hours and minutes
}
