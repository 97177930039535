import React from 'react'
import { Box, Button, FormLabel, Grid, styled, CircularProgress, Stack, Typography } from '@mui/material'
import { get, isEmpty } from 'lodash'

import { unitLabel } from '../../../common/utils/buildings'
import { fullDateTimeFormat } from '../../../common/utils/dates'
import { getDaysFromArray } from '../../../common/utils/dates'
import { SENSOR_NOT_MUTED } from '../../../common/constants/sensors'
import { HEIGHT_FULL, WIDTH_FULL } from '../../../common/constants/images'
import { disabledFormControlLabelFontColor } from '../../../common/theme/MuiFormControlLabel'

import FormCheckbox from '../../atoms/CheckmarkCell/FormCheckbox'

import SensorShowInformationRow from './SensorShowInformationRow'
import useSensorShowInformation from './useSensorShowInformation'
import { useLocation } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
  import {
  getSensorDoorAlertingHoursString,
  getSensorMotionAlertingHoursString
} from '../../../common/utils/sensors'

// Used for monitoring door's alert weekends & holidays checkbox
const FontSizeSmallTypography = styled('span')({ fontSize: 'small' })

const SensorShowInformation = ({ sensor = {}, sensorLocationPhoto = null, building = {}, unit = {} }) => {
  const { waterLabel, temperatureLabel, humidityLabel, soundLabel } = useSensorShowInformation({ sensor })
  const useQuery = new URLSearchParams(useLocation().search)
  const buildingId = useQuery.get('building_id')

  return (
    <>
      <Grid container>
        <SensorShowInformationRow label={'Device Name'} value={sensor.deviceName} />
        <SensorShowInformationRow label={unitLabel(building)} value={unit.number} />
        <SensorShowInformationRow label={'Location'} value={sensor.location} />
        <SensorShowInformationRow label={'Probe Type'} value={sensor.probeType} />
        <SensorShowInformationRow
          label={'Additional Notes'}
          value={sensor.notes}
          sx={!isEmpty(sensor.notes) ? { pb: 2, fontSize: 'small' } : {}}
        />
        <SensorShowInformationRow
          label={'Mute Status'}
          value={sensor.isMuted ? fullDateTimeFormat(sensor.muteUntil) : SENSOR_NOT_MUTED}
        />
        <SensorShowInformationRow label={'Hardware ID'} value={sensor.hardwareId} />
      </Grid>

      {/* Device Options */}
      <Grid container>
        <h6 className='general-header'>Device Options</h6>
        <Grid container direction='row' spacing={2}>
          <Grid item>
            <FormCheckbox label='In Use' checked={sensor.inUse} disabled={true} />
          </Grid>
          <Grid item>
            <FormCheckbox label='Enable Alerts' checked={sensor.enableAlerts} disabled={true} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <h6 className='general-header'>Monitoring Factors</h6>
        <Grid container direction='column'>
          {!sensor.monitorSound && !sensor.monitorPower && !sensor.countPeople && (
            <>
              <FormCheckbox label={waterLabel} checked={sensor.monitorWater} disabled={true} />
              <FormCheckbox label={temperatureLabel} checked={sensor.monitorTemperature} disabled={true} />
              <FormCheckbox label={humidityLabel} checked={sensor.monitorHumidity} disabled={true} />
              <FormCheckbox label='Door Open' checked={sensor.monitorDoor} disabled={true} />
              {sensor.monitorDoor && sensor.doorAlertHoursEnabled && (
                <Grid container direction='column' sx={{ ml: '42px' }}>
                  <FormLabel
                    disabled={true}
                    sx={{ fontSize: 'small', '&.Mui-disabled': { color: disabledFormControlLabelFontColor } }}
                  >
                    <span>
                      Alerting Days: {getDaysFromArray(get(sensor, ['doorAlertDaysArr'], []) || [])}
                    </span>
                  </FormLabel>
                  <FormLabel
                    disabled={true}
                    sx={{ fontSize: 'small', '&.Mui-disabled': { color: disabledFormControlLabelFontColor } }}
                  >
                    <span>Hours: {getSensorDoorAlertingHoursString(sensor)}</span>
                  </FormLabel>
                  <FormCheckbox
                    label={<FontSizeSmallTypography>Alert Weekends & Holidays</FontSizeSmallTypography>}
                    checked={sensor.doorAlertWeekendHolidays}
                    disabled={true}
                    size={'small'}
                    formControlLabelSx={{}} // Overrides FormCheckbox's default m: 0 and uses MUI's default styling
                    checkBoxSx={{ pt: '0px', pb: '0px' }}
                  />
                </Grid>
              )}
              <FormCheckbox label='Motion' checked={sensor.monitorMotion} disabled={true} />
              {sensor.monitorMotion && sensor.motionAlertHoursEnabled && (
                <Grid container direction='column' sx={{ ml: '42px' }}>
                  <FormLabel
                    disabled={true}
                    sx={{ fontSize: 'small', '&.Mui-disabled': { color: disabledFormControlLabelFontColor } }}
                  >
                    <span>
                      Alerting Days: {getDaysFromArray(get(sensor, ['motionAlertDaysArr'], []) || [])}
                    </span>
                  </FormLabel>
                  <FormLabel
                    disabled={true}
                    sx={{ fontSize: 'small', '&.Mui-disabled': { color: disabledFormControlLabelFontColor } }}
                  >
                    <span>Hours: {getSensorMotionAlertingHoursString(sensor)}</span>
                  </FormLabel>
                  <FormCheckbox
                    label={<FontSizeSmallTypography>Alert Weekends & Holidays</FontSizeSmallTypography>}
                    checked={sensor.motionAlertWeekendHolidays}
                    disabled={true}
                    size={'small'}
                    formControlLabelSx={{}} // Overrides FormCheckbox's default m: 0 and uses MUI's default styling
                    checkBoxSx={{ pt: '0px', pb: '0px' }}
                  />
                </Grid>
              )}
            </>
          )}
          {sensor.monitorPower && <FormCheckbox label='Power' checked disabled={true} />}
          {sensor.monitorSound && <FormCheckbox label={soundLabel} checked disabled={true} />}
          {sensor.countPeople && (
            <>
              <FormCheckbox label='People Count' checked disabled={true} />
              <Box sx={{ ml: 3 }}>
                <FontSizeSmallTypography>
                  In Direction Label: {sensor.countPeopleInLabel}
                </FontSizeSmallTypography>
              </Box>
              <Box sx={{ ml: 3 }}>
                <FontSizeSmallTypography>
                  Out Direction Label: {sensor.countPeopleOutLabel}
                </FontSizeSmallTypography>
              </Box>
            </>
          )}
          {/*<FormCheckbox label='Cleaning Request' checked={sensor.monitorButton} disabled={true} />*/}
        </Grid>
      </Grid>
      <br />

      {/* Installation Photo */}
      {sensorLocationPhoto && (
        <Grid container sx={{ justifyContent: 'center' }}>
          <h5 className='general-header'>Installation Photo</h5>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Box height={HEIGHT_FULL / 2} width={WIDTH_FULL / 2}>
              <img src={sensorLocationPhoto} alt='Sensor Location Photo' width='100%' />
            </Box>
          </Grid>
        </Grid>
      )}

      <Grid container direction='column' sx={{ mt: 3 }}>
        <Button
          variant='contained'
          color='primary'
          // size='small'
          onClick={() =>
            (window.location.href = `/buildings/${buildingId.toString()}/sensors/${sensor.id}/edit`)
          }
        >
          <EditIcon />
          &nbsp;&nbsp;Edit Sensor
        </Button>
      </Grid>
    </>
  )
}

export default SensorShowInformation
