export const SENSOR_NOT_ALERTING = 'Sensor not currently alerting'
export const SENSOR_NOT_IN_USE = 'Sensor not in use'
export const SENSOR_NOT_MUTED = 'Not Muted'

export const WATER_SENSITIVITY_SLIDER_MARKS = [
  {
    value: 0,
    label: '0 Min Wait'
  },
  {
    value: 30,
    label: 'Sensitivity'
  },
  {
    value: 60,
    label: '60 Min Wait'
  }
]

export const TEMPERATURE_SLIDER_MARKS = [
  {
    value: -50,
    label: '-50°C'
  },
  {
    value: 0,
    label: '0°C'
  },
  {
    value: 50,
    label: '50°C'
  },
  {
    value: 100,
    label: '100°C'
  }
]

export const HUMIDITY_SLIDER_MARKS = [
  {
    value: 0,
    label: '0%'
  },
  {
    value: 25,
    label: '25%'
  },
  {
    value: 50,
    label: '50%'
  },
  {
    value: 75,
    label: '75%'
  },
  {
    value: 100,
    label: '100%'
  }
]

export const SENSOR_CAPABILITY_LEAK = 'leak'
export const SENSOR_CAPABILITY_DOOR = 'door'
export const SENSOR_CAPABILITY_MOTION = 'motion'
export const SENSOR_CAPABILITY_TEMPERATURE = 'temperature'
export const SENSOR_CAPABILITY_HUMIDITY = 'humidity'
export const SENSOR_CAPABILITY_OTHER = 'other'

export const ALL_SENSOR_CAPABILITIES = [
  SENSOR_CAPABILITY_LEAK,
  SENSOR_CAPABILITY_DOOR,
  SENSOR_CAPABILITY_MOTION,
  SENSOR_CAPABILITY_TEMPERATURE,
  SENSOR_CAPABILITY_HUMIDITY,
  SENSOR_CAPABILITY_OTHER
]
