import React, { useEffect, useState } from 'react'
import { ScreenRotation } from '@mui/icons-material'
import { isMobileUserAgent } from '../../../common/utils/display'

import('../../../common/theme/stylesheets/phoneAnimation.css') // Import the CSS file

const RotatePhoneAnimation = ({ sx }) => {
  const [isPortrait, setIsPortrait] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(isMobileUserAgent() && window.innerHeight > window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Set the initial orientation

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!isPortrait) return null // Show the animation only in portrait mode

  return (
    <div className='rotate-phone' style={sx}>
      <ScreenRotation />
    </div>
  )
}

export default RotatePhoneAnimation
