import React, { useEffect, useState } from 'react'
import { useFetchIaqDataQuery, useFetchIaqOutliersQuery } from '../GraphQl/gql.generated'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material'
import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'
import { isEmpty } from 'lodash'
import usePagination from '../../../../common/hooks/usePagination'
import { StyledTableRow } from '../../../../common/theme/components/tables/StyledTableRow'
import IAQChartsCard from './IAQChartsCard'
import {
  ALERT_REPORT_DISPLAY,
  CO2_LABEL,
  CO_LABEL,
  HUMIDITY_LABEL,
  OCCUPANCY_REPORT_DISPLAY,
  OUTLIER_FILTER_TYPES,
  PM_10_LABEL,
  PM_2_5_LABEL,
  POWER_REPORT_DISPLAY,
  PRESSURE_LABEL,
  SOUND_REPORT_DISPLAY,
  TEMPERATURE_LABEL,
  TEMPERATURE_REPORT_DISPLAY,
  TVOC_LABEL
} from '../../../../common/constants/reports'
import { fullDateTimeFormat, validateDate } from '../../../../common/utils/dates'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { fetchCriteria } from '../../../../common/utils/reports'
import { useFetchSensorsCollectionLazyQuery } from '../../../pages/dashboards/components/DashboardFiltersPanel/gql.generated'

const IAQSummaryCard = ({
  filterCriteria,
  setFilterCriteria,
  criteriaDisplay,
  setCriteriaDisplay,
  reportToken,
  user
}) => {
  const [averages, setAverages] = useState([])
  const [outliers, setOutliers] = useState([])
  const [outlierFilterIndex, setOutlierFilterIndex] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('avg_co2')
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } =
    usePagination({ numRowsPerPage: 10 })

  const {
    data: resultData,
    loading,
    refetch
  } = useFetchIaqDataQuery({
    variables: {
      filters: fetchCriteria(filterCriteria),
      reportToken,
      order,
      orderBy,
      pagination: {
        page: page + 1, // TablePagination starts count pages from zero / 0
        per: rowsPerPage
      }
    }
  })

  const {
    data: resultOutliers,
    loading: loadingOutliers,
    refetch: refetchOutliers
  } = useFetchIaqOutliersQuery({
    variables: {
      filters: fetchCriteria(filterCriteria),
      reportToken,
      iaqAttribute: OUTLIER_FILTER_TYPES[outlierFilterIndex].iaqAttribute,
      lowHigh: OUTLIER_FILTER_TYPES[outlierFilterIndex].lowHigh
    }
  })

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    refetch({ order, orderBy })
  }, [order, orderBy, refetch])

  useEffect(() => {
    if (!loading) setAverages(resultData.fetchIaqData.averages)
  }, [resultData, filterCriteria])

  useEffect(() => {
    refetchOutliers({ outlierFilterIndex })
  }, [outlierFilterIndex, refetchOutliers])

  useEffect(() => {
    if (!loadingOutliers) setOutliers(resultOutliers?.fetchIaqOutliers.outliers)
  }, [resultOutliers, filterCriteria])

  const attributeLabels = {
    avg_temperature: 'Avg. ' + TEMPERATURE_LABEL,
    avg_humidity: 'Avg. ' + HUMIDITY_LABEL,
    avg_co2: 'Avg. ' + CO2_LABEL,
    avg_co: 'Avg. ' + CO_LABEL,
    avg_pressure: 'Avg. ' + PRESSURE_LABEL,
    avg_tvoc_mg_m3: 'Avg. ' + TVOC_LABEL,
    avg_pm_2_5: 'Avg. ' + PM_2_5_LABEL,
    avg_pm_10: 'Avg. ' + PM_10_LABEL
  }

  const [getSensors, { data: sensorsData, loading: sensorsLoading }] = useFetchSensorsCollectionLazyQuery()
  const triggerReportDrilldown = async (outlierRow) => {
    const eventTime = new Date(validateDate(outlierRow.event_time))
    const startDate = new Date(eventTime.getTime())
    startDate.setDate(startDate.getDate() - 1) // subtract a day

    const endDate = new Date(eventTime.getTime())
    endDate.setDate(endDate.getDate() + 1) // add a day

    const updatedfilterCriteria = {
      ...filterCriteria,
      sensorIds: [outlierRow.sensor_id.toString()],
      startDate: startDate,
      endDate: endDate,
      drilldown: true,
      lowHigh: OUTLIER_FILTER_TYPES[outlierFilterIndex].lowHigh
    }
    updatedfilterCriteria[ALERT_REPORT_DISPLAY] = false
    updatedfilterCriteria[TEMPERATURE_REPORT_DISPLAY] = false
    updatedfilterCriteria[OCCUPANCY_REPORT_DISPLAY] = false
    updatedfilterCriteria[POWER_REPORT_DISPLAY] = false
    updatedfilterCriteria[SOUND_REPORT_DISPLAY] = false

    const sensors = await getSensors({
      variables: {
        companyId: user?.companyId,
        ids: [outlierRow.sensor_id],
        pagination: {
          per: 500
        }
      }
    })

    const unit = {
      ...sensors.data.fetchSensorsCollection.collection[0].unit,
      building: sensors.data.fetchSensorsCollection.collection[0].building
    }
    const updatedDisplayCriteria = {
      ...criteriaDisplay,
      sensors: sensors.data.fetchSensorsCollection.collection,
      units: [unit],
      buildings: [unit.building]
    }
    updatedDisplayCriteria.units[0]['building'] = updatedDisplayCriteria.buildings[0]

    setFilterCriteria(updatedfilterCriteria)
    setCriteriaDisplay(updatedDisplayCriteria)
  }

  return (
    <>
      <Paper sx={{ mt: 2, p: 4 }}>
        <h4 align={'center'}>IAQ Device Summary</h4>
        <TableContainer>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow key='header'>
                <StyledTableCell align='left'>Sensor</StyledTableCell>
                {Object.keys(attributeLabels).map((key) => (
                  <StyledTableCell align='left' key={key}>
                    <TableSortLabel
                      key={key + '_label'}
                      active={orderBy === key}
                      direction={orderBy === key ? order : 'asc'}
                      onClick={() => handleRequestSort(key)}
                    >
                      {attributeLabels[key]}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className='tight-table-row'>
              {!loading && (
                <>
                  {averages.map((average) => (
                    <StyledTableRow key={average['sensor_name']} hover>
                      <StyledTableCell component='td' scope='row'>
                        {average['sensor_name']}
                      </StyledTableCell>
                      {Object.keys(attributeLabels).map((key, index) => (
                        <StyledTableCell
                          component='td'
                          scope='row'
                          key={key + average[key]}
                          style={{
                            paddingBottom: 8,
                            paddingTop: 8
                          }}
                        >
                          {average[key]}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                  {!averages?.length && (
                    <StyledTableRow hover>
                      <TableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={9}>
                        <Typography component={'h4'}>No Sensor Events Recorded</Typography>
                      </TableCell>
                    </StyledTableRow>
                  )}
                </>
              )}
              {loading && (
                <TableRow hover>
                  <TableCell align='center' colSpan={9} scope='row'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && (
            <TablePagination
              colSpan={4}
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={resultData.fetchIaqData.metadata.sensorCount || 0}
              rowsPerPage={rowsPerPage}
              page={isEmpty(resultData.fetchIaqData?.averages) ? 0 : page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ paddingBottom: 25 }}
            />
          )}
        </TableContainer>
      </Paper>
      <Paper sx={{ mt: 2, p: 4 }}>
        <h4 align={'center'}>IAQ Outlier Data</h4>

        <Box sx={{ width: '10%', pl: 30 }}>
          <Grid item xs={2} sx={{ minWidth: '270px', mt: '5px' }}>
            <FormControl fullWidth>
              <InputLabel id='since-label'>Select Metric</InputLabel>
              <Select
                disabled={loadingOutliers}
                size='small'
                value={outlierFilterIndex}
                label='Select Metric'
                onChange={(event) => setOutlierFilterIndex(event.target.value)}
              >
                {OUTLIER_FILTER_TYPES.map(({ displayName }, index) => (
                  <MenuItem value={index} key={index}>
                    {displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Box>
        <TableContainer sx={{ pr: 30, pl: 30 }}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <StyledTableCell align='left'>Sensor</StyledTableCell>
                <StyledTableCell align='left'>
                  {OUTLIER_FILTER_TYPES[outlierFilterIndex].tableDisplay}
                </StyledTableCell>
                <StyledTableCell align='left'>Recorded At</StyledTableCell>
                <StyledTableCell align='left'></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className='tight-table-row'>
              {!loadingOutliers && (
                <>
                  {outliers.map((outlierRow) => (
                    // We need to make these rows clickable so users can find more information about outliers around the time the events happened
                    <StyledTableRow
                      hover
                      // onClick={() => triggerReportDrilldown(outlierRow.sensor_id)}
                      // style={{cursor: 'pointer'}}
                      key={outlierRow.event_time}
                    >
                      {/*<StyledTableRow hover key={outlierRow.event_time}>*/}
                      <StyledTableCell component='td' scope='row'>
                        {outlierRow.sensor_name}
                      </StyledTableCell>
                      <StyledTableCell component='td' scope='row'>
                        {outlierRow[OUTLIER_FILTER_TYPES[outlierFilterIndex].iaqAttribute]}
                      </StyledTableCell>
                      <StyledTableCell component='td' scope='row'>
                        {fullDateTimeFormat(outlierRow.event_time)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {/* Public viewers cannot drilldown, this simplifies code drastically*/}
                        {user ? (
                          <IconButton aria-label='search' onClick={() => triggerReportDrilldown(outlierRow)}>
                            <ArrowForwardIosIcon />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  {!outliers?.length && (
                    <StyledTableRow hover>
                      <TableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={4}>
                        <Typography component={'h4'}>No Sensor Events Recorded</Typography>
                      </TableCell>
                    </StyledTableRow>
                  )}
                </>
              )}
              {loadingOutliers && (
                <TableRow hover>
                  <TableCell align='center' colSpan={8} scope='row'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <IAQChartsCard filterCriteria={filterCriteria} reportToken={reportToken} />
    </>
  )
}

export default IAQSummaryCard
