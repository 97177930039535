import React, { useState, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormHelperText,
  Link,
  CircularProgress,
  Stack,
  Switch,
  FormControlLabel
} from '@mui/material'
import { capitalize, get, isEmpty, isNil } from 'lodash'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { Controller } from 'react-hook-form'
import CreateUnitModal from '../../organisms/Units/CreateUnitModal'
import { getOrdinal, sensorDisplay } from '../../../common/utils/display'
import { useSensorQrLookupLazyQuery } from './GraphQl/gql.generated'
import { useGetCurrentUserTypeQuery } from '../PropertyManagersPage/GraphQl/gql.generated'
import { TYPE_SUPER_ADMIN } from '../../../common/constants/users'
import FormCheckbox from '../../atoms/CheckmarkCell'
import TooltipIcon from '../../atoms/TooltopIcon'
import ConfigSlider from '../../molecules/Sliders/ConfigSlider'
import {
  WATER_SENSITIVITY_SLIDER_MARKS,
  TEMPERATURE_SLIDER_MARKS,
  HUMIDITY_SLIDER_MARKS,
  ALL_SENSOR_CAPABILITIES,
  SENSOR_CAPABILITY_DOOR,
  SENSOR_CAPABILITY_MOTION,
  SENSOR_CAPABILITY_LEAK,
  SENSOR_CAPABILITY_TEMPERATURE,
  SENSOR_CAPABILITY_HUMIDITY
} from '../../../common/constants/sensors'
import CaptureSensorPhotoModal from './CaptureSensorPhotoModal'
import OverlaySpinner from '../../atoms/OverlaySpinner'
import ScanQrCodeDialog from '../../molecules/ScanQrCodeDialog'
import useSensorCreateEdit from './useSensorCreateEdit'
import TimeConfig from '../../molecules/TimeConfig'
import PopupMessage from '../../molecules/NotificationDialog'
import FloorGrid from '../../organisms/FloorGrid'
import useFloorLayout from './useFloorLayout'
import { atBreakpoint } from '../../../common/utils/display'

const SensorCreateEditPage = () => {
  const { sensorId, buildingId } = useParams()

  // - If new sensor, setting the sensor location should be toggled on
  // - If editing sensor, we want the location to be locked by default,
  //   unless the user indicates they want to move the sensor's location
  //   or if a location is not yet set
  const [movingSensor, setMovingSensor] = useState(!sensorId)

  const [qrModalOpen, setQrModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [showUnitModal, setShowUnitModal] = useState(false)
  const [enableManualSetup, setEnableManualSetup] = useState(false)
  const [doorHoursValues, setDoorHoursValues] = useState({ doorAlertHoursEnabled: false })
  const [motionHoursValues, setMotionHoursValues] = useState({ motionAlertHoursEnabled: false })
  const [submitError, setSubmitError] = useState('')
  const [scanWarning, setScanWarning] = useState('')
  const [sensorPurpose, setSensorPurpose] = useState('')
  const [imageSrc, setImageSrc] = useState(null)
  const [sensorCapabilities, setSensorCapabilities] = useState(ALL_SENSOR_CAPABILITIES)
  const webcamRef = useRef(null)
  const [sensorQrLookup, { loading: qrLookupLoading }] = useSensorQrLookupLazyQuery()

  const [sensorRow, setSensorRow] = useState(null)
  const [sensorCol, setSensorCol] = useState(null)
  const [zoneLayoutSet, setZoneLayoutSet] = useState(false)

  const {
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    handleSensorCreateUpdateSubmit,
    handleSensorPurposeChange,
    tempRange,
    setTempRange,
    humidityRange,
    setHumidityRange,
    waterSensitivity,
    setWaterSensitivity,
    savingSensor,
    units,
    unitsLoading,
    handleAddUnit,
    sensor,
    sensorLoading
  } = useSensorCreateEdit({
    sensorId,
    buildingId,
    setShowUnitModal,
    setSensorCapabilities,
    setDoorHoursValues,
    setMotionHoursValues,
    setSensorPurpose,
    setImageSrc,
    sensorRow,
    setSensorRow,
    sensorCol,
    setSensorCol,
    movingSensor
  })

  const hardwareId = watch('hardwareId', '')
  const inUseEnabled = watch('inUse', false)
  const tempEnabled = watch('monitorTemperature', false)
  const humidityEnabled = watch('monitorHumidity', false)
  const waterEnabled = watch('monitorWater', false)
  const doorEnabled = watch('monitorDoor', false)
  const motionEnabled = watch('monitorMotion', false)
  const soundEnabled = watch('monitorSound', false)
  const powerEnabled = watch('monitorPower', false)
  const peopleCountEnabled = watch('countPeople', false)
  const unitId = watch('unitId', null)

  const { grid, onCellClick, rotationToNorth, floorCellsLoading } = useFloorLayout({
    buildingId,
    unitId,
    units,
    sensor,
    sensorRow,
    setSensorRow,
    sensorCol,
    setSensorCol,
    zoneLayoutSet,
    setZoneLayoutSet,
    movingSensor,
    setMovingSensor
  })

  const onSubmit = (input) =>
    handleSensorCreateUpdateSubmit(
      input,
      setSubmitError,
      buildingId,
      waterEnabled,
      tempEnabled,
      humidityEnabled,
      doorEnabled,
      doorHoursValues,
      motionEnabled,
      motionHoursValues
    )

  // Get current user type
  const { data: userData } = useGetCurrentUserTypeQuery()
  const currentUserType = get(userData, ['getCurrentUser', 'type'], '')

  const capture = useCallback(() => {
    const capturedImageSrc = webcamRef.current.getScreenshot()
    setImageSrc(capturedImageSrc)
    setValue('image', capturedImageSrc)
  }, [webcamRef])

  const handleCloseScanQr = ({ scanResult = [] }) => {
    // TODO: remove this logging
    console.log('SCAN RESULT')
    console.log(scanResult)
    setScanWarning('')
    setSubmitError('')
    if (!isEmpty(scanResult) && scanResult[0].rawValue !== '') {
      sensorQrLookup({
        variables: {
          qrRawValue: scanResult[0].rawValue
        },
        onCompleted: (qrData) => {
          const sensor = get(qrData, ['sensorQrLookup'], {})

          if (!isEmpty(sensor)) {
            // If sensor already exists we let the user know they would be moving it to another location/building
            if (sensor.deployed) {
              setScanWarning(
                'Sensor already exists, if you continue you will be moving this sensor to your ' +
                  'new deployment location. Please ensure you want to move this sensor before proceeding. The sensor is ' +
                  'currently located at: ' +
                  sensorDisplay(sensor)
              )
            }

            // setHardwareId()
            setValue('hardwareId', sensor.eui, { shouldValidate: true })
            setValue('deviceName', sensor.manufacturer + ': ' + sensor.model, { shouldValidate: true })
            // For now we do not support onboarding for light sensors as there is no use case for it currently
            setSensorCapabilities(sensor.capabilities.filter((c) => c !== 'light'))
          }
        },
        onError: (error) => {
          setSubmitError(error.message)
        }
      })
    } else {
      setScanWarning('QR Code is not readable, please try again')
    }
    setQrModalOpen(false)
  }

  const isCollapsed = atBreakpoint('xl')

  // TODO: Add project to the create form, and make it intelligent so it knows the project of the last created sensor so the user doesn't have to complete that field every time
  return sensorLoading || unitsLoading ? (
    <OverlaySpinner open />
  ) : (
    <>
      <PopupMessage
        popupName={'sensorOnboardingNotification'}
        message={
          'For setting up brand new sensors, please ensure you have completed and saved the setup form before you pull the sensor battery tab.'
        }
      />
      <Box sx={{ m: 'auto', pt: 2, textAlign: 'center' }}>
        {hardwareId === '' && !enableManualSetup && (
          <>
            {/************************ Start of title repeated code (dup 1) ************************/}
            <Typography variant='h5' component='h5' sx={{ pb: 2 }}>
              {sensorId ? 'Edit' : 'Add New'} Sensor
            </Typography>
            {submitError !== '' && (
              <p className='error-text'>
                {submitError}
                <br />
                <br />
              </p>
            )}
            {scanWarning !== '' && (
              <Box
                className='warning-text'
                component='p'
                sx={{ m: 'auto', textAlign: 'center', maxWidth: '500px', mb: 2 }}
              >
                {scanWarning}
              </Box>
            )}
            {/************************ End of title repeated code (dup 1) ************************/}
            <Button variant='contained' onClick={() => setQrModalOpen(true)}>
              <QrCodeScannerIcon />
              &nbsp;&nbsp;Scan Sensor QR
            </Button>
            <Typography sx={{ mt: 2 }}>
              Don't have a QR Code?{' '}
              <Link style={{ cursor: 'pointer' }} onClick={() => setEnableManualSetup(true)}>
                Enter Sensor Manually
              </Link>
            </Typography>
          </>
        )}
        {(hardwareId !== '' || enableManualSetup) && (
          <Grid container spacing={2}>
            <Grid item xs={0} sm={0} md={0} lg={0} xl={1} />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
              {/************************ Start of title repeated code (dup 2) ************************/}
              <Typography variant='h5' component='h5' sx={{ pb: 2 }}>
                {sensorId ? 'Edit' : 'Add New'} Sensor
              </Typography>
              {submitError !== '' && (
                <p className='error-text'>
                  {submitError}
                  <br />
                  <br />
                </p>
              )}
              {scanWarning !== '' && (
                <Box
                  className='warning-text'
                  component='p'
                  sx={{ m: 'auto', textAlign: 'center', maxWidth: '500px', mb: 2 }}
                >
                  {scanWarning}
                </Box>
              )}
              {/************************ End of title repeated code (dup 2) ************************/}
              <Box
                component='form'
                onSubmit={handleSubmit(onSubmit)}
                sx={{ minWidth: '350px', maxWidth: '500px', m: 'auto' }}
                noValidate
                autoComplete='off'
              >
                <Controller
                  name='hardwareId'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      InputProps={{ readOnly: !enableManualSetup }}
                      id='hardwareId'
                      label='Hardware ID*'
                      error={!!errors.hardwareId}
                      helperText={errors.hardwareId ? errors.hardwareId.message : ''}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name='deviceName'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mb: 2 }}
                      InputProps={{ readOnly: !enableManualSetup }}
                      id='deviceName'
                      label='Make & Model*'
                      error={!!errors.deviceName}
                      helperText={
                        errors.deviceName
                          ? errors.deviceName.message
                          : 'Capabilities: ' + sensorCapabilities.map((c) => capitalize(c)).join(', ')
                      }
                      fullWidth
                    />
                  )}
                />
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormControl fullWidth>
                        <Controller
                          name='unitId'
                          control={control}
                          render={({ field }) => (
                            <>
                              <InputLabel>Zone*</InputLabel>
                              <Select
                                {...field}
                                id='unitId'
                                label='Zone*'
                                variant='outlined'
                                error={!!errors.unitId}
                                fullWidth
                                sx={{
                                  textAlign: 'left',
                                  '& .MuiSelect-select': {
                                    alignItems: 'left'
                                  }
                                }}
                              >
                                {units.map((unit, index) => (
                                  <MenuItem key={index} value={unit.id}>
                                    {unit.number} - {unit.floor + getOrdinal(unit.floor)} floor
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sx={{ m: 'auto' }}>
                      <Button
                        sx={{ p: 1.5 }}
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => setShowUnitModal(true)}
                      >
                        Add a Zone
                      </Button>
                    </Grid>
                  </Grid>
                  <FormHelperText sx={{ ml: 2, mb: 2 }} htmlFor='render-select' error={!!errors.unitId}>
                    {errors.unitId ? errors.unitId.message : 'Zone where the sensor is located'}
                  </FormHelperText>
                </Box>
                <Controller
                  name='location'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id='location'
                      sx={{ mb: 1 }}
                      label='Location*'
                      error={!!errors.location}
                      helperText={
                        errors.location ? errors.location.message : 'Sensor Installation Location within Zone'
                      }
                      fullWidth
                    />
                  )}
                />
                <Grid container>
                  <Grid item>
                    <FormControl fullWidth>
                      <Controller
                        name={'inUse'}
                        control={control}
                        render={({ field }) => (
                          <FormCheckbox
                            label={
                              <Grid container direction='row' alignItems='center'>
                                <Grid item>
                                  <Typography>In Use</Typography>
                                </Grid>
                                <Grid item>
                                  <TooltipIcon
                                    title={
                                      'In Use indicates that the sensor should be collecting data. If this is ' +
                                      'disabled, messages arriving from this sensor will be ignored.'
                                    }
                                    textFontSize={14}
                                  />
                                </Grid>
                              </Grid>
                            }
                            checked={field.value}
                            onChange={(e) => {
                              setValue('inUse', e.target.checked)
                              if (!e.target.checked) setValue('enableAlerts', false)
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <Controller
                        name='enableAlerts'
                        control={control}
                        render={({ field }) => (
                          <FormCheckbox
                            label={
                              <Grid container direction='row' alignItems='center'>
                                <Grid item>
                                  <Typography>Enable Alerts</Typography>
                                </Grid>
                                <Grid item>
                                  <TooltipIcon
                                    title={
                                      'Enable Alerts controls whether alerts are being sent out for the sensor. ' +
                                      'If "In Use" is disabled, then this flag will be ignored as data will not be ' +
                                      'collected. Disabling alerts can be for sensors where we want to collect data ' +
                                      'about the sensor but not send any alerts.'
                                    }
                                    textFontSize={14}
                                  />
                                </Grid>
                              </Grid>
                            }
                            // We don't provide alerting for people counter use case
                            disabled={!inUseEnabled || peopleCountEnabled}
                            checked={field.value}
                            onChange={(e) => setValue('enableAlerts', e.target.checked)}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={10} sx={{ pt: 2, pb: 1 }}>
                    {isNil(sensorId) && (
                      <FormControl fullWidth>
                        <InputLabel>What will this sensor monitor?</InputLabel>
                        <Select
                          disabled={qrLookupLoading}
                          value={sensorPurpose}
                          label='What will this sensor monitor?'
                          onChange={(event) => handleSensorPurposeChange(event.target.value)}
                          sx={{
                            textAlign: 'left',
                            '& .MuiSelect-select': {
                              alignItems: 'left'
                            },
                            mb: 1
                          }}
                        >
                          {sensorCapabilities.map((option) => (
                            <MenuItem value={option} key={option}>
                              {capitalize(option)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {[SENSOR_CAPABILITY_LEAK, SENSOR_CAPABILITY_DOOR].includes(sensorPurpose) && (
                    <Grid item xs={8} sx={{ pt: 1, pb: 1 }}>
                      <Controller
                        name='probeType'
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel>Probe Type</InputLabel>
                            <Select
                              {...field}
                              disabled={qrLookupLoading}
                              name='probeType'
                              label='Probe Type'
                              sx={{
                                textAlign: 'left',
                                '& .MuiSelect-select': {
                                  alignItems: 'left'
                                }
                              }}
                            >
                              {sensorPurpose === SENSOR_CAPABILITY_LEAK &&
                                ['pin', 'rope'].map((option) => (
                                  <MenuItem value={option} key={option}>
                                    {capitalize(option)}
                                  </MenuItem>
                                ))}
                              {sensorPurpose === SENSOR_CAPABILITY_DOOR && (
                                <MenuItem value={SENSOR_CAPABILITY_DOOR} key={SENSOR_CAPABILITY_DOOR}>
                                  Door Probe
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                {sensorPurpose === SENSOR_CAPABILITY_LEAK && (
                  <ConfigSlider
                    control={control}
                    sliderEnabled={waterEnabled}
                    sliderValue={waterSensitivity}
                    setSliderValue={setWaterSensitivity}
                    setValue={setValue}
                    min={0}
                    max={60}
                    marks={WATER_SENSITIVITY_SLIDER_MARKS}
                    title={'Water'}
                    fieldId={'monitorWater'}
                    tooltip={
                      'Only send an alert if the device detects water continuously for the specified number of minutes.' +
                      ' 0 minutes is most sensitive, 60 minutes is least sensitive. This helps prevent alerts from things' +
                      ' like floors being mopped or occasional condensation drips that are expected.'
                    }
                  />
                )}
                {sensorPurpose === SENSOR_CAPABILITY_DOOR && (
                  <TimeConfig
                    control={control}
                    configEnabled={doorEnabled}
                    configType={SENSOR_CAPABILITY_DOOR}
                    hoursValues={doorHoursValues}
                    setHoursValues={setDoorHoursValues}
                    setValue={setValue}
                    title={'Door'}
                    fieldId={'monitorDoor'}
                    tooltip={
                      'Set the hours of the day during which you wish this sensor to send door alerts. ' +
                      'Note that alerts must also be enabled for this sensor to send door alerts.'
                    }
                  />
                )}
                {sensorPurpose === SENSOR_CAPABILITY_MOTION && (
                  <TimeConfig
                    control={control}
                    configEnabled={motionEnabled}
                    configType={SENSOR_CAPABILITY_MOTION}
                    hoursValues={motionHoursValues}
                    setHoursValues={setMotionHoursValues}
                    setValue={setValue}
                    title={'Motion'}
                    fieldId={'monitorMotion'}
                    tooltip={
                      'Set the hours of the day during which you wish this sensor to send motion alerts. ' +
                      'Note that alerts must also be enabled for this sensor to send motion alerts.'
                    }
                  />
                )}
                {[
                  SENSOR_CAPABILITY_TEMPERATURE,
                  SENSOR_CAPABILITY_HUMIDITY,
                  SENSOR_CAPABILITY_LEAK,
                  SENSOR_CAPABILITY_DOOR,
                  SENSOR_CAPABILITY_MOTION
                ].includes(sensorPurpose) && (
                  <>
                    <ConfigSlider
                      control={control}
                      sliderEnabled={tempEnabled}
                      sliderValue={tempRange}
                      setSliderValue={setTempRange}
                      setValue={setValue}
                      min={-50}
                      max={100}
                      marks={TEMPERATURE_SLIDER_MARKS}
                      title={'Temperature'}
                      fieldId={'monitorTemperature'}
                      tooltip={
                        'Device will send alerts if the temperature rises or falls outside the specified range.'
                      }
                    />
                    <ConfigSlider
                      control={control}
                      sliderEnabled={humidityEnabled}
                      sliderValue={humidityRange}
                      setSliderValue={setHumidityRange}
                      setValue={setValue}
                      min={0}
                      max={100}
                      marks={HUMIDITY_SLIDER_MARKS}
                      title={'Humidity'}
                      fieldId={'monitorHumidity'}
                      tooltip={
                        'Device will send alerts if the humidity rises or falls outside the specified range.'
                      }
                    />
                  </>
                )}
                {sensorPurpose === 'other' && (
                  <>
                    {(isNil(sensorId) || soundEnabled) && (
                      <Box sx={{ textAlign: 'left', mt: -1, pb: 2 }}>
                        <Grid container>
                          <Grid item>
                            <Controller
                              name={'monitorSound'}
                              control={control}
                              render={({ field }) => (
                                <FormCheckbox
                                  label='Sound (dB)'
                                  checked={field.value}
                                  disabled={!isNil(sensorId)}
                                  onChange={(e) => setValue('monitorSound', e.target.checked)}
                                />
                              )}
                            />
                          </Grid>
                          {soundEnabled && (
                            <Grid item>
                              <Controller
                                name='highDecibelThreshold'
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id='highDecibelThreshold'
                                    label='High dB Threshold'
                                    type='number'
                                    sx={{ ml: 3, maxWidth: '250px' }}
                                    error={!!errors.highDecibelThreshold}
                                    helperText={
                                      errors.highDecibelThreshold
                                        ? errors.highDecibelThreshold.message
                                        : 'Alerts if dB above this level'
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    )}
                    {(isNil(sensorId) || powerEnabled) && (
                      <Box sx={{ textAlign: 'left', mt: -2, mb: 2 }}>
                        <Controller
                          name={'monitorPower'}
                          control={control}
                          render={({ field }) => (
                            <FormCheckbox
                              label='Power'
                              checked={field.value}
                              disabled={!isNil(sensorId)}
                              onChange={(e) => setValue('monitorPower', e.target.checked)}
                            />
                          )}
                        />
                      </Box>
                    )}
                    {(isNil(sensorId) || peopleCountEnabled) && (
                      <Box sx={{ textAlign: 'left', mt: -1, pb: 2 }}>
                        <Grid item>
                          <Controller
                            name={'countPeople'}
                            control={control}
                            render={({ field }) => (
                              <FormCheckbox
                                label='People Count'
                                checked={field.value}
                                disabled={!isNil(sensorId)}
                                onChange={(e) => setValue('countPeople', e.target.checked)}
                              />
                            )}
                          />
                        </Grid>
                        {peopleCountEnabled && (
                          <>
                            <Grid item>
                              <Controller
                                name='countPeopleInLabel'
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id='countPeopleInLabel'
                                    label='In Direction Label'
                                    sx={{ ml: 3, mt: 2 }}
                                    error={!!errors.countPeopleInLabel}
                                    helperText={
                                      errors.countPeopleInLabel
                                        ? errors.countPeopleInLabel.message
                                        : 'Label for the direction of people coming "in"'
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item>
                              <Controller
                                name='countPeopleOutLabel'
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id='countPeopleOutLabel'
                                    label='In Direction Label'
                                    sx={{ ml: 3, mt: 3 }}
                                    error={!!errors.countPeopleOutLabel}
                                    helperText={
                                      errors.countPeopleOutLabel
                                        ? errors.countPeopleOutLabel.message
                                        : 'Label for the direction of people going "out"'
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </Box>
                    )}
                  </>
                )}
                {!isEmpty(sensorPurpose) && (
                  <>
                    <Controller
                      name='notes'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id='notes'
                          label='Notes'
                          sx={{ mb: 1, mt: 1 }}
                          multiline
                          rows={4}
                          error={!!errors.notes}
                          helperText={
                            errors.notes ? errors.notes.message : 'Additional information about the sensor.'
                          }
                          fullWidth
                        />
                      )}
                    />
                    <Box sx={{ textAlign: 'left' }}>
                      {imageSrc && (
                        <>
                          Sensor Location Photo:
                          <img
                            src={imageSrc}
                            alt='Captured'
                            width={350}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setImageModalOpen(true)}
                          />
                        </>
                      )}
                      <Button
                        sx={{ mt: 2 }}
                        color='primary'
                        variant='contained'
                        onClick={() => setImageModalOpen(true)}
                      >
                        <AddAPhotoIcon />
                        &nbsp;&nbsp;{imageSrc ? 'Retake ' : 'Take '}Photo of Installed Sensor
                      </Button>
                    </Box>
                    {!isCollapsed && (
                      <>
                        {/************************ Start of button repeated code (dup 1) ************************/}
                        {savingSensor ? (
                          <OverlaySpinner
                            open={savingSensor}
                            messageLine1={
                              isEmpty(sensorId)
                                ? 'Please wait while the sensor is being added to the building. This may take a few seconds.'
                                : 'Saving Sensor...'
                            }
                            messageLine2={
                              isEmpty(sensorId)
                                ? 'After this step completes, you can pull the battery tab from the sensor to bring it online.'
                                : ''
                            }
                          />
                        ) : (
                          <Box sx={{ pb: 5, pt: 4 }}>
                            {!isEmpty(errors) && (
                              <Box className={'warning-text'} sx={{ pb: 3, textAlign: 'left' }}>
                                Ensure all required form fields are completed
                              </Box>
                            )}
                            <Button
                              type='submit'
                              variant='contained'
                              color='primary'
                              style={{ marginRight: 10 }}
                            >
                              Save Sensor
                            </Button>
                            <Button
                              variant='outlined'
                              color='primary'
                              onClick={() =>
                                (window.location.href = '/sensors?building_id=' + buildingId.toString())
                              }
                            >
                              Cancel
                            </Button>
                          </Box>
                        )}
                        {/************************ End of button repeated code (dup 1) ************************/}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
              {currentUserType === TYPE_SUPER_ADMIN && (
                <Stack direction='column' spacing={1} sx={{ alignItems: 'center' }}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <Typography sx={{ mt: isCollapsed ? 2 : 'auto' }} variant='h6'>
                        Set Sensor Location
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {sensorId &&
                        zoneLayoutSet &&
                        sensor?.floorCell &&
                        parseInt(sensor?.unit?.id) === parseInt(unitId) && (
                          <>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={movingSensor}
                                  onChange={() => setMovingSensor(!movingSensor)}
                                />
                              }
                              label='Edit Location'
                            />
                          </>
                        )}
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                  {floorCellsLoading && <CircularProgress size={60} />}
                  {!floorCellsLoading && (
                    <>
                      {!unitId && <Typography variant='h7'>Select a zone to set location</Typography>}
                      {unitId && (
                        <>
                          {!zoneLayoutSet && (
                            <Typography variant='h7'>
                              You must add the floor layout for this zone in order to set this sensor's
                              location.
                            </Typography>
                          )}
                          {zoneLayoutSet && (
                            <>
                              <Typography variant='h9'>North</Typography>
                              <FloorGrid grid={grid} callback={onCellClick} rotation={rotationToNorth} />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {!isEmpty(sensorPurpose) && isCollapsed && (
                    <>
                      {/************************ Start of button repeated code (dup 2) ************************/}
                      <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{ m: 'auto' }} noValidate>
                        {savingSensor ? (
                          <OverlaySpinner
                            open={savingSensor}
                            messageLine1={
                              isEmpty(sensorId)
                                ? 'Please wait while the sensor is being added to the building. This may take a few seconds.'
                                : 'Saving Sensor...'
                            }
                            messageLine2={
                              isEmpty(sensorId)
                                ? 'After this step completes, you can pull the battery tab from the sensor to bring it online.'
                                : ''
                            }
                          />
                        ) : (
                          <Box sx={{ pb: 5, pt: 4 }}>
                            {!isEmpty(errors) && (
                              <Box className={'warning-text'} sx={{ pb: 3, textAlign: 'left' }}>
                                Ensure all required form fields are completed
                              </Box>
                            )}
                            <Button
                              type='submit'
                              variant='contained'
                              color='primary'
                              style={{ marginRight: 10 }}
                            >
                              Save Sensor
                            </Button>
                            <Button
                              variant='outlined'
                              color='primary'
                              onClick={() =>
                                (window.location.href = '/sensors?building_id=' + buildingId.toString())
                              }
                            >
                              Cancel
                            </Button>
                          </Box>
                        )}
                      </Box>
                      {/************************ End of button repeated code (dup 2) ************************/}
                    </>
                  )}
                </Stack>
              )}
            </Grid>
          </Grid>
        )}

        <CreateUnitModal
          buildingId={buildingId}
          showUnitModal={showUnitModal}
          setShowUnitModal={setShowUnitModal}
          handleAddUnit={handleAddUnit}
        />

        <ScanQrCodeDialog
          handleCloseScanQr={handleCloseScanQr}
          qrModalOpen={qrModalOpen}
          setQrModalOpen={setQrModalOpen}
        />

        <CaptureSensorPhotoModal
          imageModalOpen={imageModalOpen}
          setImageModalOpen={setImageModalOpen}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          capture={capture}
          webcamRef={webcamRef}
        />
      </Box>
    </>
  )
}

export default SensorCreateEditPage
