import React, { useEffect } from 'react'

/**
 * - Each element of grid is expected to be an object with the following format
 *   {
 *     props: {  // this is the object that contains information about this cell
 *       clickable:  (boolean)  // whether or not this cell is clickable,
 *                              // if read-only grid, all cells should have this set to false.
 *       toggled: (boolean)   // whether or not this cell is toggled (on)
 *       toggledBgColor: (color string)  // This is the backgroundcolor of the cell if it is toggled
 *                                       // If toggled is true, this value must be specified.
 *       disabled: (boolean)  // If this is true, the cell is disabled.
 *                            // If this is true, you can ignore the previous 3 attributes
 *     },
 *     styleProps: {  // Optional. This contains additional style properties you want to apply to this cell.
 *                    // It has to be of the same format as a regular style object applied to a
 *                    // javascript component (e.g. maxWidth, border, etc.)
 *        ...properties
 *     }
 *   }
 *
 * - You can also specify a callback that will run when the cell is clicked. If the cell is disabled, or not clickable,
 *   this is ignored. The callback takes 4 arguments in the following order: grid, row, column, cell.
 *     * grid, row, col are just passed on from the props of the FloorCell component
 *     * cell is the actual rendered element. This is useful if you want to alter some properties of a cell if it is
 *       clicked
 *
 */
const FloorCell = ({ grid, row, col, isMouseDown, callback }) => {
  const floorCellIdx = `floorcell-${row}-${col}`
  let cell = document.getElementById(floorCellIdx)

  useEffect(() => {
    cell = document.getElementById(floorCellIdx)
    if (cell) {
      cell.className = getClasses()
      if (grid[row][col].props?.toggled) cell.style.backgroundColor = grid[row][col].props.toggledBgColor
      if (grid[row][col].props?.disabled) cell.style.cursor = 'default'
    }
  }, [grid])

  const handleClick = () => {
    if (!grid[row][col].props?.disabled) {
      cell.className = getClasses()
      if (grid[row][col].props.clickable && callback) {
        callback(grid, row, col, cell)
      }
    }
  }

  const getClasses = () => {
    const classes = {
      clickable: grid[row][col].props?.clickable,
      toggled: grid[row][col].props?.toggled
    }
    let classesStr = 'floorcell'
    for (let cls of Object.keys(classes)) {
      if (classes[cls]) classesStr += ` ${cls}`
    }
    return classesStr
  }

  return (
    <td
      id={floorCellIdx}
      className={getClasses()}
      onMouseDown={handleClick}
      onMouseEnter={() => {
        if (isMouseDown.current) handleClick()
      }}
      style={grid[row][col].styleProps ?? {}}
    />
  )
}

export default FloorCell
