import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBuildingMutationVariables = Types.Exact<{
  input: Types.UpdateBuildingInput;
}>;


export type UpdateBuildingMutation = { __typename?: 'Mutation', updateBuilding: { __typename?: 'Building', id: string, rotationToNorth: number, numFloorCellRows: number, numFloorCellColumns: number } };


export const UpdateBuildingDocument = gql`
    mutation UpdateBuilding($input: UpdateBuildingInput!) {
  updateBuilding(input: $input) {
    id
    rotationToNorth
    numFloorCellRows
    numFloorCellColumns
  }
}
    `;
export type UpdateBuildingMutationFn = Apollo.MutationFunction<UpdateBuildingMutation, UpdateBuildingMutationVariables>;

/**
 * __useUpdateBuildingMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingMutation, { data, loading, error }] = useUpdateBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBuildingMutation, UpdateBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBuildingMutation, UpdateBuildingMutationVariables>(UpdateBuildingDocument, options);
      }
export type UpdateBuildingMutationHookResult = ReturnType<typeof useUpdateBuildingMutation>;
export type UpdateBuildingMutationResult = Apollo.MutationResult<UpdateBuildingMutation>;
export type UpdateBuildingMutationOptions = Apollo.BaseMutationOptions<UpdateBuildingMutation, UpdateBuildingMutationVariables>;