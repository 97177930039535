import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFullUnitMutationVariables = Types.Exact<{
  input: Types.CreateUnitInput;
}>;


export type CreateFullUnitMutation = { __typename?: 'Mutation', createUnit: { __typename?: 'Unit', id: string, number?: string | null, floor: number, zoneType?: Types.ZoneType | null, alertEscalationMinutes: number } };

export type UpdateUnitMutationVariables = Types.Exact<{
  input: Types.UpdateUnitInput;
}>;


export type UpdateUnitMutation = { __typename?: 'Mutation', updateUnit: { __typename?: 'Unit', id: string, number?: string | null, floor: number, zoneType?: Types.ZoneType | null, alertEscalationMinutes: number } };

export type FetchFloorCellsQueryVariables = Types.Exact<{
  buildingId: Types.Scalars['ID'];
  floor?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchFloorCellsQuery = { __typename?: 'Query', fetchFloorCells: Array<{ __typename?: 'FloorCell', id: string, row: number, column: number, floor: number, numAttachedSensors: number, unit: { __typename?: 'Unit', id: string, number?: string | null } }> };

export type GetBuildingDimsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBuildingDimsQuery = { __typename?: 'Query', getBuilding: { __typename?: 'Building', id: string, buildingType: string, numFloorCellRows: number, numFloorCellColumns: number, rotationToNorth: number, sqft?: number | null } };


export const CreateFullUnitDocument = gql`
    mutation CreateFullUnit($input: CreateUnitInput!) {
  createUnit(input: $input) {
    id
    number
    floor
    zoneType
    alertEscalationMinutes
  }
}
    `;
export type CreateFullUnitMutationFn = Apollo.MutationFunction<CreateFullUnitMutation, CreateFullUnitMutationVariables>;

/**
 * __useCreateFullUnitMutation__
 *
 * To run a mutation, you first call `useCreateFullUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFullUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFullUnitMutation, { data, loading, error }] = useCreateFullUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFullUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateFullUnitMutation, CreateFullUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFullUnitMutation, CreateFullUnitMutationVariables>(CreateFullUnitDocument, options);
      }
export type CreateFullUnitMutationHookResult = ReturnType<typeof useCreateFullUnitMutation>;
export type CreateFullUnitMutationResult = Apollo.MutationResult<CreateFullUnitMutation>;
export type CreateFullUnitMutationOptions = Apollo.BaseMutationOptions<CreateFullUnitMutation, CreateFullUnitMutationVariables>;
export const UpdateUnitDocument = gql`
    mutation UpdateUnit($input: UpdateUnitInput!) {
  updateUnit(input: $input) {
    id
    number
    floor
    zoneType
    alertEscalationMinutes
  }
}
    `;
export type UpdateUnitMutationFn = Apollo.MutationFunction<UpdateUnitMutation, UpdateUnitMutationVariables>;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitMutation, { data, loading, error }] = useUpdateUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitMutation, UpdateUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(UpdateUnitDocument, options);
      }
export type UpdateUnitMutationHookResult = ReturnType<typeof useUpdateUnitMutation>;
export type UpdateUnitMutationResult = Apollo.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = Apollo.BaseMutationOptions<UpdateUnitMutation, UpdateUnitMutationVariables>;
export const FetchFloorCellsDocument = gql`
    query FetchFloorCells($buildingId: ID!, $floor: Int) {
  fetchFloorCells(buildingId: $buildingId, floor: $floor) {
    id
    row
    column
    floor
    unit {
      id
      number
    }
    numAttachedSensors
  }
}
    `;

/**
 * __useFetchFloorCellsQuery__
 *
 * To run a query within a React component, call `useFetchFloorCellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFloorCellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFloorCellsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      floor: // value for 'floor'
 *   },
 * });
 */
export function useFetchFloorCellsQuery(baseOptions: Apollo.QueryHookOptions<FetchFloorCellsQuery, FetchFloorCellsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchFloorCellsQuery, FetchFloorCellsQueryVariables>(FetchFloorCellsDocument, options);
      }
export function useFetchFloorCellsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchFloorCellsQuery, FetchFloorCellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchFloorCellsQuery, FetchFloorCellsQueryVariables>(FetchFloorCellsDocument, options);
        }
export type FetchFloorCellsQueryHookResult = ReturnType<typeof useFetchFloorCellsQuery>;
export type FetchFloorCellsLazyQueryHookResult = ReturnType<typeof useFetchFloorCellsLazyQuery>;
export type FetchFloorCellsQueryResult = Apollo.QueryResult<FetchFloorCellsQuery, FetchFloorCellsQueryVariables>;
export const GetBuildingDimsDocument = gql`
    query GetBuildingDims($id: ID!) {
  getBuilding(id: $id) {
    id
    buildingType
    numFloorCellRows
    numFloorCellColumns
    rotationToNorth
    sqft
  }
}
    `;

/**
 * __useGetBuildingDimsQuery__
 *
 * To run a query within a React component, call `useGetBuildingDimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingDimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingDimsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuildingDimsQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingDimsQuery, GetBuildingDimsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingDimsQuery, GetBuildingDimsQueryVariables>(GetBuildingDimsDocument, options);
      }
export function useGetBuildingDimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingDimsQuery, GetBuildingDimsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingDimsQuery, GetBuildingDimsQueryVariables>(GetBuildingDimsDocument, options);
        }
export type GetBuildingDimsQueryHookResult = ReturnType<typeof useGetBuildingDimsQuery>;
export type GetBuildingDimsLazyQueryHookResult = ReturnType<typeof useGetBuildingDimsLazyQuery>;
export type GetBuildingDimsQueryResult = Apollo.QueryResult<GetBuildingDimsQuery, GetBuildingDimsQueryVariables>;