import { useState, useEffect, useDebugValue } from 'react'
import { useGetBuildingDimsQuery } from '../ZoneCreateEditPage/GraphQl/gql.generated'
import { useFetchFloorCellsLazyQuery } from '../ZoneCreateEditPage/GraphQl/gql.generated'
import { idxToColor } from '../../../common/utils/floorLayouts'
import { get } from 'lodash'
import { floor } from 'lodash'

const useFloorLayout = ({
  buildingId,
  unitId,
  units,
  sensor,
  sensorRow,
  setSensorRow,
  sensorCol,
  setSensorCol,
  zoneLayoutSet,
  setZoneLayoutSet,
  movingSensor,
  setMovingSensor
}) => {
  const [grid, setGrid] = useState([[]])

  const { data: buildingData, loading: buildingLoading } = useGetBuildingDimsQuery({
    variables: {
      id: buildingId
    }
  })
  const building = get(buildingData, ['getBuilding'], {})

  const setUpGrid = (data) => {
    const num_rows = building.numFloorCellRows
    const num_columns = building.numFloorCellColumns

    const floorCells = data.fetchFloorCells
    const newGrid = new Array(num_rows).fill(null).map(() => new Array(num_columns).fill(null))
    for (let row = 0; row < num_rows; row++) {
      for (let col = 0; col < num_columns; col++) {
        // We now want to fill out the props of the empty cells
        // By default, all cells that don't belong to the zone are disabled.
        // For information on what all of these mean, please check out the documentation for FloorCell
        newGrid[row][col] = {
          unitId: null,
          props: {
            disabled: true
          },
          styleProps: !movingSensor ? { filter: 'opacity(75%)' } : {}
        }
      }
    }
    let foundZone = false
    for (let cell of floorCells) {
      const cellUnitId = parseInt(cell.unit.id)
      if (cellUnitId === parseInt(unitId)) foundZone = true
      newGrid[cell.row][cell.column].unitId = cellUnitId
      newGrid[cell.row][cell.column].props = {
        clickable: cellUnitId === parseInt(unitId) && movingSensor,
        toggled: cellUnitId === parseInt(unitId),
        toggledBgColor: idxToColor(0),
        disabled: cellUnitId !== parseInt(unitId) // Enabling all cells that belong to the zone
      }
    }
    setGrid(newGrid)
    setZoneLayoutSet(foundZone)
    // setFloorUnits(floorUnits)
  }

  const [fetchFloorCells, { data: floorCellsData, loading: floorCellsLoading }] = useFetchFloorCellsLazyQuery(
    {
      variables: {
        buildingId,
        floor: units.filter((unit) => parseInt(unit.id) === parseInt(unitId))[0]?.floor
      },
      onCompleted: (data) => {
        setUpGrid(data)
      },
      fetchPolicy: 'network-only',
      defaultOptions: {
        notifyOnNetworkStatusChange: true
      }
    }
  )

  const onCellClick = (grid, row, col, floorCellElement) => {
    // Reset the color of the previously selected element, if any
    const prevFloorCellElement = document.getElementById(`floorcell-${sensorRow}-${sensorCol}`)
    if (prevFloorCellElement)
      prevFloorCellElement.style.backgroundColor = grid[sensorRow][sensorCol].props.toggledBgColor

    // If user clicks on a different cell, select it
    // If user clicks on the same cell, deselect it
    if (sensorRow != row || sensorCol != col) {
      floorCellElement.style.backgroundColor = 'red'
      setSensorRow(row)
      setSensorCol(col)
    } else {
      setSensorRow(null)
      setSensorCol(null)
    }
  }

  useEffect(() => {
    setZoneLayoutSet(false)
    if (unitId && units.length) {
      fetchFloorCells()
    }
    if (sensor?.floorCell && parseInt(sensor.unit?.id) === parseInt(unitId)) {
      setSensorRow(sensor.floorCell.row)
      setSensorCol(sensor.floorCell.column)
    } else {
      setSensorRow(null)
      setSensorCol(null)
    }
  }, [unitId, units])

  useEffect(() => {
    if (floorCellsData) setUpGrid(floorCellsData)
  }, [movingSensor])

  useEffect(() => {
    // If sensor already has a set location, highlight it when user selects corresponding unit/zone
    if (zoneLayoutSet) {
      if (sensorRow !== null && sensorCol !== null) {
        const floorCellElement = document.getElementById(`floorcell-${sensorRow}-${sensorCol}`)
        if (floorCellElement) floorCellElement.style.backgroundColor = 'red'
      } else {
        setMovingSensor(true)
      }
    }
  }, [zoneLayoutSet, grid])

  return {
    grid,
    onCellClick,
    floorCellsLoading,
    rotationToNorth: building.rotationToNorth
  }
}

export default useFloorLayout
